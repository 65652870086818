/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useGlitchAnimation } from '../../components/Glitch'
import { Message } from '../../components/Message'
import { Tile } from '../../components/Tile'
import { countByPrevYearCumulated, data } from '../../data'
import { useAnimatedProps } from '../../hooks/useAnimatedProps'
import { useAnimatedStyle, useAnimatedStyleVector } from '../../hooks/useAnimatedStyle'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import { useFrame } from '../../hooks/useFrame'
import messages from '../../data/messages.json'
import {
  eventTypesToColors,
  eventTypesToDisabledScale,
  eventTypeToDisabled,
  IDS_36,
} from '../../utils'
import S from './S02_Wall.module.css'
import iconLegend from '../../assets/icon-legend.svg'
import { useNavigate } from 'react-router'
import { MessagePost } from '../../components/MessagePost'
import { zip } from 'lodash'
import { scaleLinear } from 'd3-scale'
import { persistScroll } from '../../components/Scroller'
import { LegendMobile } from '../../components/LegendMobile'
import { BUILD_WALL_ANIMATIONS } from '../../buildWall'
import {
  EXAMPLE_POST_1,
  EXAMPLE_POST_2,
  EXAMPLE_POST_3,
  EXAMPLE_POST_4,
  EXAMPLE_POST_5,
} from '../../constants'
import firstMp3 from '../../assets/mp3/2018.mp3'
import secondMp3 from '../../assets/mp3/2019.mp3'
import thirdMp3 from '../../assets/mp3/2020.mp3'
import fourthMp3 from '../../assets/mp3/2021.mp3'
import { ReactComponent as PauseIcon } from '../../assets/pause.svg'
import { ReactComponent as PlayIcon } from '../../assets/play.svg'
import WaveMobile from '../../components/WaveMobile'

const useAudio = (url) => {
  const [audio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(false)
  const [current, setCurrent] = useState(0)

  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    playing ? audio.play() : audio.pause()
  }, [playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    audio.addEventListener('timeupdate', () => setCurrent(audio.currentTime))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
      audio.removeEventListener('timeupdate', () => setCurrent(0))
    }
  }, [])

  return [playing, toggle, current, audio.duration]
}

function _SampleTileHighlight({ id }, ref) {
  const { width, height } = useFrame()

  const tileWidth = width / 30
  const tileHeight = height / Math.ceil(data.length / 30)

  const left = ((id % 30) * width) / 30 - 40 + tileWidth / 2
  const top = (height / Math.ceil(data.length / 30)) * Math.floor(id / 30) - 40 + tileHeight / 2

  return (
    <div ref={ref} className={S.messageSample} style={{ left, top }}>
      <Tile
        style={{
          width: tileWidth,
          height: tileHeight,
        }}
        colors={eventTypesToColors(data[id].event_type)}
      />
    </div>
  )
}

const SampleTileHighlight = React.forwardRef(_SampleTileHighlight)

function _AudioMessage({ caption, transcript, duration, source, year }, ref) {
  const [playing, toggle, currentTime, durationTime] = useAudio(source)
  return (
    <div className={S.audio} ref={ref}>
      <p className={S.audioHeader}>{caption}</p>
      <div className={S.audioTrack}>
        {playing ? (
          <PauseIcon style={{ width: 32, height: 32 }} onClick={toggle} />
        ) : (
          <PlayIcon style={{ width: 32, height: 32 }} onClick={toggle} />
        )}
        <div style={{ flexDirection: 'column', marginLeft: '16px' }}>
          <WaveMobile currentTime={currentTime} duration={durationTime} playing={playing} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={S.audioDuration}>{duration}</div>
            <div className={S.audioYear}>{year}</div>
          </div>
        </div>
      </div>
      <p className={S.audioFooter}>{transcript}</p>
    </div>
  )
}

const AudioMessage = React.forwardRef(_AudioMessage)

function blendColors(colors1, colors2, alpha) {
  return zip(colors1, colors2).map(([color1, color2]) => {
    return scaleLinear().domain([0, 1000]).range([color1, color2]).clamp(true)(alpha)
  })
}

function createFillStyle(ctx, x, y, width, height, colors) {
  if (colors.length === 1) {
    return colors[0]
  } else {
    var grd = ctx.createLinearGradient(x, y, x, y + height)
    for (let i = 0; i < colors.length; i++) {
      grd.addColorStop((i / (colors.length - 1)).toString(), colors[i])
    }
    return grd
  }
}

function drawRect(ctx, datum, index, aaa, width, height) {
  let rect
  const { stepFilter, intensity, ...steps } = aaa
  const step = steps[`step${datum.year}`]
  const fullyVisible = Math.floor(step - 3)
  const partlyVisible = fullyVisible + 3
  let opacity = 0
  let tileWidth = width / 30
  let tileHeight = Math.ceil(height / Math.ceil(data.length / 30))
  let tileLeft = ((index % 30) * width) / 30
  let tileTop = (height / Math.ceil(data.length / 30)) * Math.floor(index / 30)
  const backgroundIntense = eventTypesToColors(datum.event_type)
  const backgroundLight = eventTypesToDisabledScale(datum.event_type)
  const bg = blendColors(backgroundLight, backgroundIntense, intensity ?? 0)
  if (stepFilter === 0) {
    const delegate = BUILD_WALL_ANIMATIONS[datum.animationPreset]
    let ax
    const ix = index - countByPrevYearCumulated[datum.year]
    if (ix < fullyVisible) {
      ax = delegate(1, tileWidth, tileHeight, tileTop, tileLeft, bg)
    } else if (ix < partlyVisible) {
      ax = delegate((step - ix) / 3, tileWidth, tileHeight, tileTop, tileLeft, bg)
    } else {
      ax = delegate(0, tileWidth, tileHeight, tileTop, tileLeft, bg)
    }
    rect = {
      width: tileWidth,
      height: tileHeight,
      left: tileLeft,
      top: tileTop,
      opacity: 1,
      background: bg,
      ...ax,
      phase: 1,
    }
  } else {
    const isViolence = datum.topic.includes('Violence and Criminal Behavior')
    const isSafety = datum.topic.includes('Safety')
    const isObjectableContent = datum.topic.includes('Objectionable Content')
    const isTwoOrMore = datum.topic.includes(',')
    const isMessage36 = IDS_36.includes(datum.ID)
    if (stepFilter <= 1000) {
      if (isViolence) {
        opacity = 1
      } else {
        opacity = 1 - stepFilter / 1000
      }
    } else if (stepFilter > 1000 && stepFilter <= 2000) {
      if (isObjectableContent && isViolence) {
        opacity = 1
      } else if (isObjectableContent && !isViolence) {
        opacity = (stepFilter - 1000) / 1000
      } else if (!isObjectableContent && isViolence) {
        opacity = 1 - (stepFilter - 1000) / 1000
      } else {
        opacity = 0
      }
    } else if (stepFilter > 2000 && stepFilter <= 3000) {
      if (isSafety && isObjectableContent) {
        opacity = 1
      } else if (isSafety && !isObjectableContent) {
        opacity = (stepFilter - 2000) / 1000
      } else if (!isSafety && isObjectableContent) {
        opacity = 1 - (stepFilter - 2000) / 1000
      } else {
        opacity = 0
      }
    } else if (stepFilter > 3000 && stepFilter <= 4000) {
      if (isMessage36 && isSafety) {
        opacity = 1
      } else if (isMessage36 && !isSafety) {
        opacity = (stepFilter - 3000) / 1000
      } else if (!isMessage36 && isSafety) {
        opacity = 1 - (stepFilter - 3000) / 1000
      } else {
        opacity = 0
      }
    } else if (stepFilter > 4000 && stepFilter <= 5000) {
      if (isTwoOrMore && isMessage36) {
        opacity = 1
      } else if (isTwoOrMore && !isMessage36) {
        opacity = (stepFilter - 4000) / 1000
      } else if (!isTwoOrMore && isMessage36) {
        opacity = 1 - (stepFilter - 4000) / 1000
      } else {
        opacity = 0
      }
    } else if (stepFilter > 5000 && stepFilter <= 6000) {
      if (isTwoOrMore) {
        opacity = 1
      } else if (!isTwoOrMore) {
        opacity = (stepFilter - 5000) / 1000
      }
    } else {
      opacity = 1
    }
    rect = {
      width: tileWidth,
      height: tileHeight,
      left: tileLeft,
      top: tileTop,
      opacity: opacity,
      background: bg,
    }
  }

  const fillStyle = createFillStyle(
    ctx,
    rect.left,
    rect.top,
    rect.width,
    rect.height,
    rect.background
  )

  ctx.globalAlpha = rect.opacity
  ctx.fillStyle = fillStyle
  ctx.fillRect(rect.left, rect.top, rect.width, rect.height)
}

export function Wall({ scrollId, progress, animation, jumpTo }) {
  const [openLegend, setOpenLegend] = useState(false)
  const animCtx = useAnimationContext({ numTiles: data.length })
  const titleRef = useRef()
  const firstTileRef = useRef()
  const tilesRef = useRef()
  const yearSwatchContainerRef = useRef()
  const yearSwatchRef = useRef()
  const numberTilesContainerRef = useRef()
  const numberTilesRef = useRef()
  const messagesFirstRef = useRef()
  const messagesRef = useRef()
  const messagesLastRef = useRef()
  const messagesViolenceRef = useRef()
  const messagesPostViolenceRef = useRef()
  const messagesPostObjectableRef = useRef()
  const messagesPostSafetyRef = useRef()
  const messagesPost36Ref = useRef()
  const messagesObjectableRef = useRef()
  const messages36Ref = useRef()
  const messagesTwoOrMore = useRef()
  const messagesSafetyRef = useRef()
  const firstAudioRef = useRef()
  const iconDetailRef = useRef()
  const iconLegendRef = useRef()
  const messageViolenceSampleRef = useRef()
  const messageObjectableSampleRef = useRef()
  const messageSafetySampleRef = useRef()
  const message36SampleRef = useRef()
  const audiosRef = useRef()
  const secondAudioRef = useRef()
  const thirdAudioRef = useRef()
  const fourthAudioRef = useRef()
  const audiosInnerRef = useRef()

  const canvasRef = useRef()

  const scrollEmulationRef = useRef(null)

  const { width, height } = useFrame()

  const navigate = useNavigate()

  useGlitchAnimation(animation.animations.glitch(progress, animCtx), progress)
  useAnimatedStyle(firstTileRef, animation.animations.tile_zero(progress, animCtx))
  useAnimatedStyle(titleRef, animation.animations.title(progress, animCtx))
  const tilesAnimator = useMemo(() => {
    return animation.animations.tile_n(progress, animCtx)
  }, [progress, animCtx])

  useAnimatedStyle(canvasRef, (...args) => {
    const aaa = tilesAnimator(...args)
    if (!aaa) {
      return null
    }
    let ctx = canvasRef.current.getContext('2d')
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    let i = 0
    for (const datum of data) {
      drawRect(ctx, datum, i, aaa, width, height)
      i += 1
    }
    return {}
  })

  const tileNumberAnimator = useMemo(() => {
    return animation.animations.number_tiles(progress, animCtx)
  }, [progress, animCtx])
  const yearSwatchAnimator = useMemo(() => {
    return animation.animations.year_swatch(progress, animCtx)
  }, [progress, animCtx])
  useAnimatedProps(yearSwatchRef, (...args) => {
    const aaa = yearSwatchAnimator(...args)
    if (!aaa) {
      return null
    }
    const { textContent } = aaa
    return { textContent: Math.floor(textContent).toString(10) }
  })
  useAnimatedStyle(
    yearSwatchContainerRef,
    animation.animations.year_swatch_container_style(progress, animCtx)
  )
  useAnimatedProps(numberTilesRef, (...args) => {
    const aaa = tileNumberAnimator(...args)
    if (!aaa) {
      return null
    }
    const { textContent } = aaa
    return { textContent: Math.floor(textContent).toString(10) }
  })
  useAnimatedStyle(
    numberTilesContainerRef,
    animation.animations.number_tiles_container_style(progress, animCtx)
  )
  useAnimatedStyle(messagesFirstRef, animation.animations.messages_first(progress, animCtx))
  // useAnimatedStyle(messagesRef, animation.animations.messages(progress, animCtx))
  useAnimatedStyle(messagesLastRef, animation.animations.messages_last(progress, animCtx))
  useAnimatedStyle(messagesViolenceRef, animation.animations.messages_violence(progress, animCtx))
  useAnimatedStyle(messages36Ref, animation.animations.messages_36(progress, animCtx))
  useAnimatedStyle(messagesTwoOrMore, animation.animations.messages_two_or_more(progress, animCtx))
  useAnimatedStyle(
    messagesPostViolenceRef,
    animation.animations.messages_violence_post(progress, animCtx)
  )
  useAnimatedStyle(
    messagesObjectableRef,
    animation.animations.messages_objectable(progress, animCtx)
  )
  useAnimatedStyle(
    messagesPostObjectableRef,
    animation.animations.messages_objectable_post(progress, animCtx)
  )
  useAnimatedStyle(messagesSafetyRef, animation.animations.messages_safety(progress, animCtx))
  useAnimatedStyle(
    messagesPostSafetyRef,
    animation.animations.messages_safety_post(progress, animCtx)
  )
  useAnimatedStyle(messagesPost36Ref, animation.animations.messages_36_post(progress, animCtx))
  useAnimatedStyle(iconDetailRef, animation.animations.icon_detail(progress, animCtx))
  useAnimatedStyle(iconLegendRef, animation.animations.icon_legend(progress, animCtx))
  useAnimatedStyle(message36SampleRef, animation.animations.messages_36_sample(progress, animCtx))
  useAnimatedStyle(
    messageViolenceSampleRef,
    animation.animations.violence_sample(progress, animCtx)
  )
  useAnimatedStyle(
    messageObjectableSampleRef,
    animation.animations.objectable_sample(progress, animCtx)
  )
  useAnimatedStyle(messageSafetySampleRef, animation.animations.safety_sample(progress, animCtx))

  useAnimatedStyle(audiosRef, animation.animations.audios(progress, animCtx))
  useAnimatedStyle(firstAudioRef, animation.animations.audio_1(progress, animCtx))
  useAnimatedStyle(secondAudioRef, animation.animations.audio_2(progress, animCtx))
  useAnimatedStyle(thirdAudioRef, animation.animations.audio_3(progress, animCtx))
  useAnimatedStyle(fourthAudioRef, animation.animations.audio_4(progress, animCtx))

  return (
    <div
      style={{ height: '100vh' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="broken-promises"
      data-jump-displacement="500"
    >
      <div className="menu-placeholder" />
      <div className={S.wall}>
        <div className={S.part} ref={titleRef}>
          <p className={S.section}>Section I</p>
          <div className={S.title}>Broken Promises_</div>
        </div>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
          <Tile
            ref={firstTileRef}
            style={{ width: 61, height: 207, position: 'absolute' }}
            colors={eventTypesToColors(data[EXAMPLE_POST_1 - 1].event_type)}
          />
          <canvas ref={canvasRef} width={width} height={height}></canvas>
        </div>
        <div className={S.yearSwatchContainer} ref={yearSwatchContainerRef}>
          <div className={S.yearSwatchLabel}>year</div>
          <div className={S.yearSwatch} ref={yearSwatchRef}></div>
        </div>
        <div className={S.numberTilesContainer} ref={numberTilesContainerRef}>
          <div className={S.numberTilesLabel}>violations</div>
          <div className={S.numberTiles} ref={numberTilesRef}></div>
        </div>
        <div ref={messagesFirstRef} className={S.messages} style={{ bottom: 0 }}>
          <Message style={{ width: 182 }} body={messages[0]} />
          <Message style={{ width: 250 }} body={messages[1]} />
          <MessagePost
            isStyleMobile
            style={{ width: 210 }}
            isFirstMessage
            promiseId={EXAMPLE_POST_1}
          />
          <Message style={{ width: 278, marginTop: 30 }} body={messages[2]} />
          <Message style={{ width: 285, marginBottom: 30 }} body={messages[3]} />
          <div className={S.scrollArrow}>
            Scroll to load a database <br />
            of major offenses.
          </div>
          <div className={S.arrowDiv}>
            <div className={S.arrowEdge} />
          </div>
        </div>
        <div ref={messagesLastRef} className={S.messages}>
          <Message style={{ width: 280 }} boxShadow body={messages[4]} />
        </div>
        <div ref={messagesViolenceRef} className={S.messages}>
          <Message style={{ width: 250 }} boxShadow body={messages[5]} />
          <Message
            style={{
              width: 260,
              left: 18,
              marginBottom: 150,
              marginRight: 'calc(100vw - 260px - 36px)',
              backgroundColor: '#485475',
              color: '#FFF',
              borderRadius: '8px 8px 8px 1px',
            }}
            body={messages[6]}
            boxShadow
          />
          <Message boxShadow style={{ width: 260 }} body={messages[7]} />
        </div>
        <MessagePost
          boxShadow
          isStyleMobile
          promiseId={EXAMPLE_POST_2}
          ref={messagesPostViolenceRef}
          style={{ width: 225 }}
        />
        <SampleTileHighlight id={EXAMPLE_POST_2 - 1} ref={messageViolenceSampleRef} />
        <div ref={messagesObjectableRef} className={S.messages}>
          <Message style={{ width: 270 }} boxShadow body={messages[8]} />
          <Message
            boxShadow
            style={{
              width: 260,
              marginBottom: 150,
              marginRight: 'calc(100vw - 260px - 36px)',
              backgroundColor: '#485475',
              borderRadius: '8px 8px 8px 1px',
              color: '#FFF',
            }}
            body={messages[9]}
          />
          <Message style={{ width: 285 }} boxShadow body={messages[10]} />
        </div>
        <MessagePost
          boxShadow
          isStyleMobile
          ref={messagesPostObjectableRef}
          promiseId={EXAMPLE_POST_3}
          style={{ width: 210, marginTop: 120, marginLeft: 10 }}
        />
        <SampleTileHighlight id={EXAMPLE_POST_3 - 1} ref={messageObjectableSampleRef} />
        <div ref={messagesSafetyRef} className={S.messages}>
          <Message style={{ width: 250 }} boxShadow body={messages[11]} />
          <Message
            boxShadow
            style={{
              width: 260,
              marginBottom: 150,
              marginRight: 'calc(100vw - 260px - 36px)',
              backgroundColor: '#485475',
              borderRadius: '8px 8px 8px 1px',
              color: '#FFF',
            }}
            body={messages[12]}
          />
          <Message style={{ width: 240 }} boxShadow body={messages[13]} />
        </div>
        <MessagePost
          boxShadow
          isStyleMobile
          ref={messagesPostSafetyRef}
          promiseId={EXAMPLE_POST_4}
          style={{ width: 210, marginLeft: -50 }}
        />
        <SampleTileHighlight id={EXAMPLE_POST_4 - 1} ref={messageSafetySampleRef} />
        <div ref={messages36Ref} className={S.messages}>
          <Message style={{ width: 230 }} boxShadow body={messages[14]} />
        </div>
        <MessagePost
          boxShadow
          isStyleMobile
          ref={messagesPost36Ref}
          promiseId={EXAMPLE_POST_5}
          style={{ width: 266, marginTop: 140 }}
        />
        <SampleTileHighlight id={EXAMPLE_POST_5 - 1} ref={message36SampleRef} />
        <div ref={messagesTwoOrMore} className={S.messages}>
          <Message style={{ width: 260 }} boxShadow body={messages[15]} />
        </div>
        {openLegend && <LegendMobile setOpenLegend={setOpenLegend} />}
        <div
          onClick={() => {
            persistScroll()
            navigate(`/violations`)
          }}
          className={S.iconDetail}
          ref={iconDetailRef}
        >
          <div className={S.buttonDetail}>Click to explore</div>
        </div>

        <div
          onClick={() => {
            setOpenLegend(true)
            document.getElementsByTagName('body')[0].style.overflow = 'hidden'
          }}
          className={S.iconLegend}
          ref={iconLegendRef}
        >
          <div className={S.buttonLegend}>Color legend</div>
        </div>

        <div
          className={S.audios}
          ref={audiosRef}
          onTouchStart={(e) => {
            scrollEmulationRef.current = {
              initialElement: audiosRef.current.scrollLeft,
              initialTouch: e.touches[0].clientX,
            }
          }}
          onTouchMove={(e) => {
            const delta = e.touches[0].clientX - scrollEmulationRef.current.initialTouch
            let position = scrollEmulationRef.current.initialElement - delta
            audiosRef.current.scrollTo(position, 0)
          }}
          onTouchEnd={() => {}}
          onTouchCancel={() => {}}
        >
          <div ref={firstAudioRef} className={S.audiosAndMessage}>
            <Message
              style={{ width: 295 }}
              body={'By <strong>2018</strong>, we recorded <strong>162</strong> violations.'}
            />
            <AudioMessage
              source={firstMp3}
              caption="Voice message from Mark"
              transcript={
                '“So this was a major breach of trust, and I’m really sorry that this happened”'
              }
              year="2018"
              duration="0:05"
            />
          </div>
          <div ref={secondAudioRef} className={S.audiosAndMessage}>
            <Message
              style={{ width: 299 }}
              body={'By <strong>2019</strong>, we recorded <strong>268</strong> violations.'}
            />
            <AudioMessage
              source={secondMp3}
              caption="Voice message from Sheryl"
              transcript={'“We know we need to do better”'}
              duration="0:02"
              year="2019"
            />
          </div>
          <div ref={thirdAudioRef} className={S.audiosAndMessage}>
            <Message
              style={{ width: 303 }}
              body={'By <strong>2020</strong>, we recorded <strong>394</strong> violations.'}
            />
            <AudioMessage
              source={thirdMp3}
              caption="Voice message from Mark"
              transcript={'“It was largely an operational mistake”'}
              duration="0:04"
              year="2020"
            />
          </div>
          <div ref={fourthAudioRef} className={S.audiosAndMessage}>
            <Message
              style={{ width: 295 }}
              body={'By <strong>2021</strong>, we recorded <strong>415</strong> violations.'}
            />
            <AudioMessage
              source={fourthMp3}
              caption="Voice message from Mark"
              transcript={'“There will always be some mistakes”'}
              duration="0:02"
              year="2021"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
