export default {
  key: 'S01',
  sequence: [
    {
      type: 'interpolate',
      key: 'card',
      duration: 200,
      from: {
        transform: ({ height, width }) => {
          const videoHeight = height + 80 - 256
          const videoWidth = width * 0.6
          const frameHeight = height
          const frameWidth = width
          const scaleX = frameWidth / videoWidth
          const scaleY = frameHeight / videoHeight
          const scale = Math.max(scaleX, scaleY)
          const aboveVideo = 27 + 20 + 80
          const belowVideo = height - videoHeight - aboveVideo
          const t = (belowVideo - aboveVideo) / 2
          return `scale(${scale}) translateY(${t}px)`
        },
      },
      to: {
        transform: 'scale(1) translateY(0px)',
      },
    },
    {
      type: 'interpolate',
      key: 'glitch',
      duration: 200,
      from: {
        glitch: 0,
      },
      to: {
        glitch: 1500,
      },
    },
  ],
}
