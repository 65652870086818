import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/swiper-react.esm.js'
import { split } from 'lodash'
import S from './DetailPromise.module.css'
import dataset from './data/dataset.json'
import { TopBar } from './components/TopBar'
import { eventTypesToColors, eventTypeToColor } from './utils'
import { Tile } from './components/Tile'

import 'swiper/swiper-bundle.css'
import closeIcon from './assets/close-detail.svg'
import { ReactComponent as ArrowSwipeLeft } from './assets/arrowSwipeLeft.svg'
import { ReactComponent as ArrowSwipeRight } from './assets/arrowSwipeRight.svg'
import iconSensitive from './assets/icon-sensitive.svg'
import { useFrame } from './hooks/useFrame'

function getBackground(event_type) {
  const colors = eventTypesToColors(event_type)
  const background =
    typeof colors === 'string'
      ? colors
      : Array.isArray(colors) && colors.length === 1
      ? colors[0]
      : `linear-gradient(${colors.join(', ')})`
  return background
}

export default function DetailPromise() {
  const [id, setId] = useState(202)
  const [showSensitive, setShowSensitive] = useState(false)
  const swiperRef = useRef()
  const tilesRef = useRef()
  const { width, height } = useFrame()

  let navigate = useNavigate()

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(201, false, false)
    }
  }, [swiperRef])

  useEffect(() => {
    const tile = document.getElementById(`ID${id}`)
    if (tile) {
      const y = tile.getBoundingClientRect().left
      const tileRefLeft = tilesRef.current.scrollLeft
      tilesRef.current.scrollTo({ left: tileRefLeft + y - width / 2 + 20 })
    }
  }, [id])

  return (
    <div>
      <TopBar />
      <Swiper
        ref={swiperRef}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => {
          setShowSensitive(false)
          setId(swiper.activeIndex + 1)
        }}
      >
        {dataset.map((promise) => (
          <SwiperSlide key={promise.ID}>
            <div
              className={S.CardPromise}
              style={{ background: getBackground(promise.event_type) }}
            >
              <div className={S.CardBody}>
                <div className={S.BlockLeftRotate}>
                  <div
                    className={S.CloseDetail}
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    <img src={closeIcon} />
                  </div>
                  <div className={S.NumberBlock}>
                    <div className={S.DatePromise}>{promise.date}</div>
                    <div className={S.BorderLine}></div>
                    <div className={S.NumberPromise}>VIOLATION #{promise.ID}</div>
                  </div>
                </div>
                <div style={{ height: 'calc(100% - 97px)', overflow: 'hidden' }}>
                  <div className={S.TitlePromise}>{promise.title}</div>
                  <div className={S.ImagePromise}>
                    {promise.is_sensitive === 'yes' && !showSensitive ? (
                      <div className={S.SensitiveContent}>
                        <div>
                          <img src={iconSensitive} />
                        </div>
                        <div>Sensitive content</div>
                        <div className={S.ViewButton} onClick={() => setShowSensitive(true)}>
                          VIEW
                        </div>
                      </div>
                    ) : (
                      <img
                        className={S.ImageRadius}
                        width="100%"
                        height={221}
                        src={`/violations/${promise.ID}.jpg`}
                        alt={`Violation #${promise.ID}`}
                      />
                    )}
                  </div>
                  <div className={S.topicBox}>
                    <div className={S.labelTopic}>Topic</div>
                    <div className={S.TopicName}>{promise.topic}</div>
                  </div>
                  <div>
                    <div className={S.labelTopic}>Event type</div>
                    <div className={S.EventList}>
                      {split(promise.event_type, ',').map((event) => (
                        <div className={S.EventContainer} key={event}>
                          <div
                            className={S.CircleEvent}
                            style={{ background: eventTypeToColor(event.trim()) }}
                          ></div>
                          <div className={S.NameEvent}>{event}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className={S.SwipeHint}>
                <ArrowSwipeLeft />
                Swipe to change card
                <ArrowSwipeRight />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={S.ListPromises} ref={tilesRef}>
        {dataset.map((datum, i) => {
          return (
            <div
              onClick={() => {
                setShowSensitive(false)
                swiperRef.current.slideTo(datum.ID - 1)
              }}
              id={`ID${datum.ID}`}
              key={i}
            >
              <Tile
                number={datum.ID === id ? datum.ID : null}
                style={{
                  width: datum.ID === id ? 56 : 20,
                  height: 56,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderLeft: datum.ID === id ? '3px solid white' : 0,
                  borderRight: datum.ID === id ? '3px solid white' : 0,
                  borderTop: datum.ID === id ? 0 : '3px solid white',
                }}
                colors={eventTypesToColors(datum.event_type)}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
