import React, { useMemo, useState } from 'react'
import S from './Message.module.css'
import dataset from '../data/dataset.json'
import iconSensitive from '../assets/icon-sensitive.svg'
import { find } from 'lodash'
import { dateFormatter, getBackground } from '../utils'
import { useFrame } from '../hooks/useFrame'

function _MessagePost(
  { style, promiseId, className, isFirstMessage = false, isStyleMobile = false },
  ref
) {
  const promise = find(dataset, { ID: promiseId })
  const [showSensitive, setShowSensitive] = useState(false)

  const { width, height, isMobile } = useFrame()

  const widthAvailable = isMobile ? width : width - 375
  const tileWidth = widthAvailable / 30
  const cardWidth = isMobile ? 210 : 338
  const left = ((promiseId % 30) * widthAvailable) / 30 - 60 + tileWidth / 2
  const leftToUse = useMemo(() => {
    if (left < cardWidth) {
      return left + cardWidth + tileWidth * 3
    }
    return left
  }, [left])

  return (
    <div
      style={{
        background: getBackground(promise.event_type),
        ...style,
        left: isFirstMessage
          ? undefined
          : isMobile
          ? leftToUse > width / 1.2
            ? leftToUse - 250
            : leftToUse - 160
          : leftToUse,
        position: isFirstMessage ? 'relative' : 'absolute',
      }}
      className={S.MessagePost + ' ' + className}
      ref={ref}
    >
      <div className={S.MessagePostBody}>
        <div className={isStyleMobile ? 'data-message-mobile' : 'data-message'}>
          {dateFormatter(promise.date)}
        </div>
        <div className={isStyleMobile ? 'strong-text-message-mobile' : 'strong-text-message'}>
          {promise.title}
        </div>
        <div>
          {promise.is_sensitive === 'yes' && !showSensitive ? (
            <div className={S.SensitiveContent} onClick={() => setShowSensitive(true)}>
              <div>
                <img src={iconSensitive} />
              </div>
              <div>SENSITIVE CONTENT</div>
              <div className={S.ViewButton} onClick={() => setShowSensitive(true)}>
                View
              </div>
            </div>
          ) : (
            <img src={`/violations/${promiseId}.jpg`} width={'100%'} />
          )}
        </div>
      </div>
    </div>
  )
}

export const MessagePost = React.forwardRef(_MessagePost)
