/* eslint-disable react/jsx-max-props-per-line */
import React, { useRef, useContext, useState } from 'react'
import { useGlitchAnimation } from '../../components/Glitch'
import { createPortal } from 'react-dom'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import { IntroLegend } from '../../components/IntroLegend'
import S from './S01_Video.module.css'
import { TopBarDesktop } from '../../components/TopBarDesktop'
import { ScrollerTools } from '../../components/Scroller'
import { ReactComponent as ScrollDownIcon } from '../../assets/scrollDown.svg'

import leftOne from '../../assets/images/photo_left_1.png'
import leftTwo from '../../assets/images/photo_left_2.png'
import leftThree from '../../assets/images/photo_left_3.png'
import leftFour from '../../assets/images/photo_left_4.png'
import rightOne from '../../assets/images/photo_right_1.png'
import rightTwo from '../../assets/images/photo_right_2.png'
import rightThree from '../../assets/images/photo_right_3.png'
import rightFour from '../../assets/images/photo_right_4.png'

export function Video({ scrollId, progress, animation, jumpTo, videoRef }) {
  const scroller = useContext(ScrollerTools)
  const [muted, setMuted] = useState(true)
  const cardRef = useRef()
  const animCtx = useAnimationContext()
  const refLegend = useRef()

  useGlitchAnimation(animation.animations.glitch(progress, animCtx), progress)


  const node = (
    <div>
      <TopBarDesktop jumpTo={jumpTo} progress={progress} />
    </div>
  )

  return (
    <div
      // style={{ height: '100vh', background: '#bcc3d6', position: 'relative' }}
      className={S.sectionContainer}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="intro"
      data-jump-displacement="0"
    >
      {createPortal(node, document.body)}
      <div className="menu-placeholder-desktop" />
      <div className={S.fbLikeCardWarp} ref={cardRef}>
        <div className={S.fbMockLeft}>
          <div className={S.fbMockLeftContent}>
              <img className={S.imageLanding} src={leftOne} />
              <img className={S.imageLanding} src={leftTwo} />
              <img className={S.imageLanding} src={leftThree} />
              <img className={S.imageLanding} src={leftFour} />
          </div>
        </div>
        <div className={S.fbMockCenter}>
          <div className={S.fbLikeCard}>


              <div className={S.landingTitle}>
                <div>
                <b>Facebook</b> executives have repeatedly promised to fix their platform.
                </div>
                <div style={{margin:'16px'}}>
                Time and time again, they've failed to deliver.
                </div>
              </div>
              <div className={S.landingContainer}>
                <div className={S.landingDescription}>
                  <div>We catalogued hundreds of Facebook's <b>broken promises</b></div>
                  <div>into an <span onClick={() => {
                          scroller.jumpTo('the-wall')
                        }} style={{cursor:'pointer'}}> <u>interactive database</u></span>, spanning 16 categories:</div>
                </div>
                <IntroLegend ref={refLegend} />
              </div>
            </div>
            
        </div>

        <div className={S.fbMockRight}>
        <div className={S.fbMockRightContent}>
              <img className={S.imageLanding} src={rightOne} />
              <img className={S.imageLanding} src={rightTwo} />
              <img className={S.imageLanding} src={rightThree} />
              <img className={S.imageLanding} src={rightFour} />
          </div>
        </div>
      </div>
      <div className={S.scrollDown}>
          Scroll down <span style={{ fontWeight: 'normal' }}></span>
        <ScrollDownIcon style={{ width: '100%', height: '100%' }} />
    </div>
    </div>
  )
}
