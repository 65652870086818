import { useEffect, useRef } from 'react'
import { registerAnimator } from './useAnimateEngine'

export function useAnimatedProps(domRef, propsInterpolator) {
  const styleInterpolatorFn = useRef(propsInterpolator)
  useEffect(() => {
    styleInterpolatorFn.current = propsInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRef.current) {
        const styleObject = styleInterpolatorFn.current()
        if (styleObject) {
          for (const key in styleObject) {
            if (key === "textContent") {
              domRef.current.textContent = styleObject[key]
            } else {
              domRef.current.setAttribute(key, styleObject[key])
            }
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRef])
}

export function useAnimatedPropsNested(domRef, selector, propsInterpolator) {
  const styleInterpolatorFn = useRef(propsInterpolator)
  useEffect(() => {
    styleInterpolatorFn.current = propsInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRef.current) {
        const styleObject = styleInterpolatorFn.current()
        if (styleObject) {
          for (const key in styleObject) {
            const element = domRef.current.querySelector(selector)
            if (element) {
              element.setAttribute(key, styleObject[key])
            }
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRef, selector])
}

export function useAnimatedPropsMulti(domRef, selector, propsInterpolator) {
  const styleInterpolatorFn = useRef(propsInterpolator)
  useEffect(() => {
    styleInterpolatorFn.current = propsInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRef.current) {
        const elements = domRef.current.querySelectorAll(selector)
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i]
          const styleObject = styleInterpolatorFn.current(element)
          if (styleObject) {
            for (const key in styleObject) {
              if (element) {
                element.setAttribute(key, styleObject[key])
              }
            }
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRef, selector])
}
