import React from 'react'
import Footer from '../../components/Footer'
import { Reports } from '../../components/Reports'
import S from './S05_Repository.module.css'

export default function DiveDeeper({ scrollId, animation }) {
  return (
    <div
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="repository"
      data-jump-displacement="80"
      style={{ height: '2821px' }}
    >
      <div className={S.RelatedArticles}>
        <h5 className={S.RelatedArticlesTitle}>
          TTP has been exposing Facebook’s Broken Promises for years. Explore the series_
        </h5>
        <div className={S.Posts}>
          <Reports />
        </div>
      </div>
      <Footer />
    </div>
  )
}
