import React, { useState, useEffect } from 'react'
import { getReports } from '../queries/reports'
import S from './Reports.module.css'
import { useFrame } from '../hooks/useFrame'

export const Reports = () => {
  const { isMobile } = useFrame()
  // const [articles, setArticles] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // useEffect(() => {
  //   const getArticles = async () => {
      
  //     try {
  //       const response = await getReports()
  //       setArticles(response)
  //       setError(null)
  //     } catch (err) {
  //       setError(err.message)
  //       setArticles(null)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  //   getArticles()
  // }, [])

  // if (error) {
  //   return null
  // }

  // if (loading) {
  //   return <div>Loading...</div>
  // }

  const articles = [
    {
        "title": "On Facebook, Far-Right Militias Stoke Trucker Convoy Headed",
        "category": "Broken Promises",
        "date": "February 28, 2022",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/d4bfe007-7341-41cf-b485-420bdac5e6e7_trucker-banner.jpeg?auto=compress,format",
        "description": "Facebook has failed to remove U.S.-based militia groups from its platform. Now they’re organizing support for the trucker protests making their way to Washington.",
        "uid": "facebook-far-right-militias-stoke-trucker-convoy-headed-dc"
    },
    {
        "title": "Facebook’s Repeat Fail on Harmful Teen Ads",
        "category": "Broken Promises",
        "date": "October 01, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/446a8129-ce97-4198-a3c1-e50e3abe710c_hero093021.jpg?auto=compress,format",
        "description": "Facebook restricted ad targeting of teens following investigations by TTP and Reset Australia. But TTP’s latest experiment shows Facebook still hasn’t fixed the problem.",
        "uid": "facebooks-repeat-fail-harmful-teen-ads"
    },
    {
        "title": "Spot Check: Facebook Still a Haven for Human Smuggling",
        "category": "Broken Promises",
        "date": "September 23, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/b45261a5-e712-45af-ab10-48a821b80c70_hs-smugg-banner.jpg?auto=compress,format",
        "description": "Facebook insists it doesn’t allow human smugglers to use its platform. But TTP’s latest research shows Facebook continues to host a market for illegal border crossings. ",
        "uid": "spot-check-facebook-still-haven-human-smuggling"
    },
    {
        "title": "Facebook’s New Toxic Stew: Militia Groups and Vaccine Conspiracies",
        "category": "Broken Promises",
        "date": "August 17, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/ea86ce7b-bdef-45f6-bda2-0ef2a44b8bfb_stew-header.jpg?auto=compress,format",
        "description": "Far-right militia groups flourish on Facebook despite a promised crackdown. Now they’re prime spreaders of misinformation about the Covid vaccines.",
        "uid": "facebooks-new-toxic-stew-militia-groups-and-vaccine-conspiracies"
    },
    {
        "title": "False Election Audit Claims Surge on Facebook",
        "category": "Broken Promises",
        "date": "August 05, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/0f56f253-ed81-4655-9fbd-1853b22e7f85_ss-banner-072721.jpg?auto=compress,format",
        "description": "Facebook failed to stem the flow of election conspiracies that led to the Capitol riot. Now, it’s failing again with misinformation about Arizona’s election ‘audit.’",
        "uid": "false-election-audit-claims-surge-facebook"
    },
    {
        "title": "Human Smuggling Rampant on Facebook Amid Border Surge",
        "category": "Broken Promises",
        "date": "June 10, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/7239cb48-1ac2-4717-8c81-d049afa932ea_060821-banner.jpg?auto=compress,format",
        "description": "TTP found more Facebook pages and groups offering illegal border crossing services. They show prices and routes—and often promise easy passage.",
        "uid": "human-smuggling-rampant-facebook-amid-border-surge"
    },
    {
        "title": "Pills, Cocktails, and Anorexia: Facebook Allows Harmful Ads to Target Teens",
        "category": "Broken Promises",
        "date": "May 04, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/a206f7ad-5f19-47bb-8777-f2fd8c994ecb_cover-teen-ads.jpg?auto=compress,format",
        "description": "A TTP experiment found that Facebook approved a range of troubling advertisements for children aged 13 to 17. The findings echo a similar study in Australia.",
        "uid": "pills-cocktails-and-anorexia-facebook-allows-harmful-ads-target-teens"
    },
    {
        "title": "Facebook Teems with Human Smugglers Luring Migrants",
        "category": "Broken Promises",
        "date": "April 16, 2021",
        "imageUrl": "https://images.prismic.io/techtransparencyproject/f3679079-6d6a-4131-a982-edf580be6bdb_smuggling-feature.jpg?auto=compress,format",
        "description": "Facebook says it doesn’t allow human exploitation. But smugglers are using the platform to offer migrants illegal passage across the southern U.S. border.",
        "uid": "facebook-teems-human-smugglers-luring-migrants"
    }
]

  return articles.map((article) => (
    <div key={article.uid}>
      <div className={S.Post}>
        <a
          href={'https://www.techtransparencyproject.org/articles/' + article.uid}
          target={'_blank'}
          className={S.imgContainer}
        >
          {!isMobile ? (
            <img height={230} width={410} src={article.imageUrl} style={{ objectFit: 'cover' }} />
          ) : (
            <img height={105} width={120} style={{ objectFit: 'cover' }} src={article.imageUrl} />
          )}
        </a>
        <div className={S.TextBlockPost}>
          <div className={S.FacebookLabel}>{article.category}</div>
          <div className={S.DatePost}>{article.date}</div>
          <a
            href={'https://www.techtransparencyproject.org/articles/' + article.uid}
            target={'_blank'}
            className={S.TitlePost}
          >
            {article.title}
          </a>
          {!isMobile && <div className={S.DescriptionPost}>{article.description}</div>}
        </div>
      </div>
    </div>
  ))
}
