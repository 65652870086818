
export default {
  key: 'S06',
  sequence: [
    // {
    //   type: 'still',
    //   duration: 300,
    // },
  ],
}
