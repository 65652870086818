import React, { useState } from 'react'
import { TopBarDesktop } from '../../components/TopBarDesktop'
import { COLORS } from '../../utils'
import FirstPdf from '../../assets/downloads/TTP_Broken Promises_Appendix A.pdf'
import SecondPdf from '../../assets/downloads/TTP_Broken Promises_Appendix B.pdf'
import DatasetCsv from '../../assets/downloads/TTP_Broken Promises_dataset.xlsx'
import S from './About.module.css'

export default function AboutDesktop() {
  const [section, setSection] = useState(null)
  const [sectionHeight, setSectionHeight] = useState(0)
  return (
    <div className={S.About}>
      <TopBarDesktop />
      <div className={S.BlockAccordions}>
        <div className={S.ItemAccordion}>
          <div
            className={S.TitleAccordion}
            onClick={(e) => {
              if (section === 'about') {
                setSectionHeight(0)
                setSection('null')
              } else {
                setSection('about')
                setSectionHeight(document.querySelector('#about-about').scrollHeight)
              }
            }}
          >
            <span>About the project</span>
          </div>
          <div
            id="about-about"
            className={S.ItemExpanded}
            style={{ maxHeight: section === 'about' ? sectionHeight : 0 }}
          >
            <p>
              For years, Facebook has been promising to crack down on the abuse of its social media
              platforms by scammers, violent criminals, and extremists. Those efforts have time and
              again come up short, leading to a cycle of scandal the company seems unable—or
              unwilling—to break.
            </p>
            <p>
              In an effort to track Facebook’s responses to these repeated violations of its
              Community Standards, the Tech Transparency Project has built and analyzed a database
              of news reports about those violations. While this database does not capture every
              violation, it does create a representative sample for analysis.
            </p>
            <p>
              The database includes publicly reported violations of Facebook’s Community Standards
              on harmful content as defined under three primary sections of the company’s policy: I.
              Violence and Criminal Behavior; II. Safety; and III. Objectionable Content. The
              database examines violations of these Community Standards from January 2018 to March
              2021.
            </p>
          </div>
        </div>
        <div className={S.ItemAccordion}>
          <div
            className={S.TitleAccordion}
            onClick={(e) => {
              if (section === 'methodology') {
                setSectionHeight(0)
                setSection('null')
              } else {
                setSection('methodology')
                setSectionHeight(document.querySelector('#about-methodology').scrollHeight)
              }
            }}
          >
            <span>Methodology</span>
          </div>
          <div
            className={S.ItemExpanded}
            id="about-methodology"
            style={{ maxHeight: section === 'methodology' ? sectionHeight : 0 }}
          >
            <p>
              Full dataset is available to download{' '}
              <a href={DatasetCsv} className={S.link} target="_blank">
                here
              </a>
              .
            </p>
            <p>
              I. Identify search terms based on language in Facebook’s Community Standards sections
              for I. Violence and Criminal behavior; II. Safety; III. Objectionable Content.
            </p>
            <p>
              II. Search Google News for new stories that reference “Facebook + KEY TERM” during
              each month between January 2018 and March 2021. Collect all unique hits for the first
              10 pages of results for each search.{' '}
              <a target="_blank" href={FirstPdf} className={S.link}>
                Download Appendix A
              </a>{' '}
              for list of key terms.
            </p>
            <p>
              III. Catalog collected news stories based on a) the relevant Community Standard
              guideline(s) and b) Facebook’s response to the incident.{' '}
              <a target="_blank" href={SecondPdf} className={S.link}>
                Download Appendix B
              </a>{' '}
              for the coding rubric.
            </p>
            <p>
              This project took the original categorization of event types based on Facebook
              Community Standards and regrouped them for design purposes. See the chart below for
              details:
            </p>
            <table cellSpacing={0} cellPadding={0} className={S.TableMethodology}>
              <tr className={S.TableTitle}>
                <td colSpan={2}>Event types groups used in this website</td>
                <td>Original event types</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Abuse and Nudity'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Abuse and Nudity</td>
                <td className={S.TableTextCell}>
                  <div>Abuse and Nudity</div>
                  <div>Abuse or Nudity</div>
                  <div>Adult Nudity and Sexual Activity</div>
                  <div>Child Sexual Exploitation</div>
                  <div>Human Exploitation</div>
                  <div>Sexual Exploitation of Adults</div>
                  <div>Sexual Exploitation</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Alcohol and Drugs'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Alcohol and Drugs</td>
                <td className={S.TableTextCell}>
                  <div>Alcohol/Tobacco</div>
                  <div>Marijuana and pharmaceutical drugs</div>
                  <div>Non-medical drugs</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Animal Crimes'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Animal Crimes</td>
                <td className={S.TableTextCell}>
                  <div>Endangered species</div>
                  <div>Harm against animals</div>
                  <div>Live non-endangered animals</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Bullying and Harassment'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Bullying and Harassment</td>
                <td className={S.TableTextCell}>Bullying and Harassment</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Criminal Activity'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Criminal Activity</td>
                <td className={S.TableTextCell}>
                  <div>Criminal Activity</div>
                  <div>Organized Violence</div>
                  <div>Terrorist Activity</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Firearms'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Firearms</td>
                <td className={S.TableTextCell}>Firearms</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Fraud and Deception'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Fraud and Deception</td>
                <td className={S.TableTextCell}>
                  <div>Deception</div>
                  <div>Fraud</div>
                  <div>Voter and/or Census Interference</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Harm Against Property'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Harm against property</td>
                <td className={S.TableTextCell}>Harm against property</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Hate Speech'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Hate Speech</td>
                <td className={S.TableTextCell}>
                  <div>Hate Speech</div>
                  <div>Organized Hate</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Historical Artifacts'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Historical Artifacts</td>
                <td className={S.TableTextCell}>Historical Artifacts</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Manipulated Media'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Manipulated Media</td>
                <td className={S.TableTextCell}>Manipulated Media</td>
              </tr>

              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Privacy and Image Rights'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Privacy and Image Rights</td>
                <td className={S.TableTextCell}>Privacy and Image Rights</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Regulated Goods'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Regulated Goods</td>
                <td className={S.TableTextCell}>Regulated Goods</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Suicide and Self-Injury'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Suicide and Self-Injury</td>
                <td className={S.TableTextCell}>Suicide and Self-Injury</td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Violence and Incitement'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Violence and Incitement</td>
                <td className={S.TableTextCell}>
                  <div>Harm against people</div>
                  <div>Human blood</div>
                  <div>Incitement</div>
                  <div>Mass murder</div>
                  <div>Violence</div>
                  <div>Violence and incitement</div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={S.TableColorTile}
                    style={{ background: COLORS['Violent and Graphic Content'] }}
                  ></span>
                </td>
                <td className={S.TableTextCell}>Violent and Graphic Content</td>
                <td className={S.TableTextCell}>Violent and graphic content</td>
              </tr>
              <tr>
                <td>
                  <span className={S.TableColorTile} style={{ background: COLORS['None'] }}></span>
                </td>
                <td className={S.TableTextCell}>None</td>
                <td className={S.TableTextCell}>None</td>
              </tr>
            </table>
          </div>
        </div>
        <div className={S.ItemAccordion}>
          <div
            className={S.TitleAccordion}
            onClick={(e) => {
              if (section === 'tech') {
                setSectionHeight(0)
                setSection('null')
              } else {
                setSection('tech')
                setSectionHeight(document.querySelector('#about-tech').scrollHeight)
              }
            }}
          >
            <span>Tech Transparency Project</span>
          </div>
          <div
            id="about-tech"
            className={S.ItemExpanded}
            style={{ maxHeight: section === 'tech' ? sectionHeight : 0 }}
          >
            <p>
              Tech Transparency Project is a research initiative of Campaign for Accountability, a
              501(c)(3) non-profit, nonpartisan watchdog organization that uses research, litigation
              and aggressive communications to expose how decisions made behind the doors of
              corporate boardrooms and government offices impact Americans’ lives.
            </p>
          </div>
        </div>
      </div>
      <div className={S.Signature}>
        <div className={S.LabelSignature}>Report written by</div>
        <div className={S.SignatureStrong}>Tech Transparency Project </div>
      </div>
      <div className={S.SignatureLast}>
        <div className={S.LabelSignature}>Design by</div>
        <div className={S.SignatureStrong}>Accurat </div>
      </div>
    </div>
  )
}
