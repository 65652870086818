import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Mobile } from './Mobile'
import { FrameDetection } from './components/MobileDetection'
import { useAnimateEngine } from './hooks/useAnimateEngine'
import { useFrame } from './hooks/useFrame'
import DetailPromise from './DetailPromise'
import { Desktop } from './Desktop'
import About from './sections/mobile/About'
import AboutDesktop from './sections/desktop/About'

function AppLogic() {
  const { isMobile } = useFrame()

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={isMobile ? <Mobile /> : <Desktop />} />
          <Route exact path="/violations" element={<DetailPromise />} />
          <Route exact path="/about" element={isMobile ? <About /> : <AboutDesktop />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default function App() {
  useAnimateEngine()

  return (
    <FrameDetection>
      <AppLogic />
    </FrameDetection>
  )
}
