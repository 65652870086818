import React from 'react'
import { hamburgerIconWrapper } from './HamburgerIconSVG.module.css'

export default function HamburgerIconSVG() {
  return (
    <div className={hamburgerIconWrapper}>
      <div>
        <div>
          <svg
            width="36"
            height="4"
            viewBox="0 0 36 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="2.94922"
              height="3.10843"
              transform="matrix(1 4.23855e-07 1.80315e-08 -1 0 3.10938)"
              fill="#33cccc"
            />
            <rect
              x="35.3711"
              y="3.10938"
              width="30.4561"
              height="3.10843"
              transform="rotate(180 35.3711 3.10938)"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div>
        <div>
          <svg
            width="36"
            height="4"
            viewBox="0 0 36 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="2.94922"
              height="3.10843"
              transform="matrix(1 4.23855e-07 1.80315e-08 -1 0 3.10938)"
              fill="#33cccc"
            />
            <rect
              x="35.3711"
              y="3.10938"
              width="30.4561"
              height="3.10843"
              transform="rotate(180 35.3711 3.10938)"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div>
        <div>
          <svg
            width="36"
            height="4"
            viewBox="0 0 36 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="2.94922"
              height="3.10843"
              transform="matrix(1 4.23855e-07 1.80315e-08 -1 0 3.10938)"
              fill="#33cccc"
            />
            <rect
              x="35.3711"
              y="3.10938"
              width="30.4561"
              height="3.10843"
              transform="rotate(180 35.3711 3.10938)"
              fill="black"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
