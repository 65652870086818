import React, { useState } from 'react'
import { ReactComponent as LogoShort } from '../assets/logo-ttp.svg'
import { ReactComponent as TwitterSVG } from '../assets/twitter.svg'
import { ReactComponent as MailSVG } from '../assets/mail.svg'
import S from './Footer.module.css'

export default function Footer() {
  const [, setEmail] = useState(null)
  const [input, setInput] = useState(null)

  const onKeyHandler = (e) => {
    if (e.key === 'Enter') {
      setEmail(input)
    }
    return
  }

  const onChangeHandler = (e) => {
    const text = e.target.value
    setInput(text)
    return
  }

  return (
    <div className={S.FooterWrapper}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <a href="https://www.techtransparencyproject.org/" target={'_blank'}>
          <LogoShort />
        </a>
        <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
          <a href="https://twitter.com/ttp_updates" target={'_blank'}>
            <TwitterSVG />
          </a>
          <a href="mailto:info@techtransparencyproject.org">
            <MailSVG />
          </a>
        </div>
      </div>
      <div className={S.FooterContent}>
        <div style={{flex: 1}}>
          <div className={S.SupportUs}>
            Support us
            <a href="https://secure.donationpay.org/campaignforaccountability/" target={'_blank'}>
              <button className={S.Button}>Donate</button>
            </a>
          </div>
          <div
            className={S.SupportUs}
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            Sign up for updates
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <input
                className={S.searchInput}
                placeholder="Enter your email address"
                value={input || ''}
                onKeyPress={onKeyHandler}
                onChange={onChangeHandler}
              />
              <a href={`https://googletransparencyproject.us13.list-manage.com/subscribe/post?u=526f2cfe31e697a3727f7e6ad&id=32e4c976f0&MERGE0=${input}`} target={'_blank'}>
              <button className={S.Button}>Sign up</button>
              </a>
            </div>
          </div>
        </div>
          <a href="https://www.techtransparencyproject.org/" target={'_blank'}>
        <div className={S.SupportUs}>
            TTP main website
        </div>
          </a>
      </div>
    </div>
  )
}
