/* eslint-disable react/jsx-max-props-per-line */
import React, { useContext, useState, useRef } from 'react'
import { useGlitchAnimation } from '../../components/Glitch'
import { createPortal } from 'react-dom'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import { IntroLegendMobile } from '../../components/IntroLegendMobile'
import { ReactComponent as ScrollDownIcon } from '../../assets/scrollDown.svg'
import S from './S01_Video.module.css'
import { TopBar } from '../../components/TopBar'
import { ScrollerTools } from '../../components/Scroller'

export function Video({ scrollId, progress, animation, jumpTo, videoRef }) {
  const scroller = useContext(ScrollerTools)
  const [muted, setMuted] = useState(true)
  const animCtx = useAnimationContext()
  const refLegend = useRef()

  useGlitchAnimation(animation.animations.glitch(progress, animCtx), progress)
  const node = (
    <div>
      <TopBar jumpTo={jumpTo} progress={progress} />
    </div>
  )

  return (
    <div
      style={{ height: '100vh', maxHeight: '-webkit-fill-available' }}
      className={S.sectionContainer}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="intro"
      data-jump-displacement="0"
    >
      {createPortal(node, document.body)}
      <div className="menu-placeholder" />
      <div className={S.fbLikeCardWarp}>
       
          <div className={S.fbLikeCard}>


              <div className={S.landingTitle}>
                <div>
                <b>Facebook</b> executives have repeatedly promised to fix their platform.
                </div>
                <div style={{margin:'16px'}}>
                Time and time again, they've failed to deliver.
                </div>
              </div>
              <div className={S.landingContainer}>
                <div className={S.landingDescription}>
                  <div>We catalogued hundreds of Facebook's <b>broken promises</b> into an 
                  <span onClick={() => {
                          scroller.jumpTo('the-wall')
                        }} style={{cursor:'pointer'}}> <u>interactive database</u></span>
                        , spanning 16 categories:</div>
                </div>
                <IntroLegendMobile ref={refLegend} />
              </div>
            </div>
            </div>


        <div className={S.scrollDown}>
        <ScrollDownIcon style={{ height: '24px', opacity: .5 }} />
          <span style={{marginLeft: '16px',marginRight:'16px'}}>Scroll down</span>
          <ScrollDownIcon style={{ height: '24px', opacity: .5 }} />
        </div>
    
    </div>
  )
}
