import { scaleLinear } from 'd3-scale'
import React from 'react'

export default function Wave({ playing, currentTime, duration }) {
  const domainAudio = scaleLinear().domain([0, duration]).range([0, 172])

  return (
    <svg
      width="172"
      height="26"
      viewBox="0 0 172 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <line
          opacity={playing ? 1 : 0.5}
          x1="1.13428"
          y1="4"
          x2="1.13428"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="4.38672"
          y1="8"
          x2="4.38672"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="7.63916"
          y1="8"
          x2="7.63916"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="10.8914"
          y1="3"
          x2="10.8914"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="14.1438"
          y1="2"
          x2="14.1438"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="17.3962"
          y1="6"
          x2="17.3962"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="20.6482"
          y1="7"
          x2="20.6482"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="23.9006"
          y1="10"
          x2="23.9006"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="27.1533"
          y1="5"
          x2="27.1533"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="30.4053"
          y1="7"
          x2="30.4053"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="33.6577"
          y1="6"
          x2="33.6577"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="36.9102"
          y1="5"
          x2="36.9102"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="40.1628"
          y1="3"
          x2="40.1628"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="43.4148"
          y1="2"
          x2="43.4148"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="46.6672"
          y1="9"
          x2="46.6672"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="49.9197"
          y1="4"
          x2="49.9197"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="53.1716"
          y1="9"
          x2="53.1716"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="56.4243"
          y1="11"
          x2="56.4243"
          y2="14"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="59.6768"
          y1="2"
          x2="59.6768"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="62.9287"
          y1="1"
          x2="62.9287"
          y2="25"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="66.1812"
          y1="11"
          x2="66.1812"
          y2="14"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="69.4336"
          y1="9"
          x2="69.4336"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="72.6863"
          y1="8"
          x2="72.6863"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="75.9382"
          y1="5"
          x2="75.9382"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="79.1904"
          y1="2"
          x2="79.1904"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="82.4431"
          y1="8"
          x2="82.4431"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="85.6951"
          y1="4"
          x2="85.6951"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="88.9478"
          y1="4"
          x2="88.9478"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="92.2"
          y1="9"
          x2="92.2"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="95.4526"
          y1="2"
          x2="95.4526"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="98.7046"
          y1="6"
          x2="98.7046"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="101.957"
          y1="7"
          x2="101.957"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="105.209"
          y1="7"
          x2="105.209"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="108.461"
          y1="5"
          x2="108.461"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="111.714"
          y1="2"
          x2="111.714"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="114.967"
          y1="7"
          x2="114.967"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="118.219"
          y1="3"
          x2="118.219"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="121.471"
          y1="8"
          x2="121.471"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="124.723"
          y1="4"
          x2="124.723"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="127.976"
          y1="8"
          x2="127.976"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="131.228"
          y1="10"
          x2="131.228"
          y2="15"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="134.48"
          y1="10"
          x2="134.48"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="137.733"
          y1="4"
          x2="137.733"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="140.985"
          y1="3"
          x2="140.985"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="144.238"
          y1="9"
          x2="144.238"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="147.49"
          y1="1"
          x2="147.49"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="150.742"
          y1="5"
          x2="150.742"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="153.994"
          y1="7"
          x2="153.994"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="157.247"
          y1="7"
          x2="157.247"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="160.5"
          y1="6"
          x2="160.5"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="163.751"
          y1="4"
          x2="163.751"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="167.004"
          y1="8"
          x2="167.004"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="170.256"
          y1="4"
          x2="170.256"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        {currentTime < duration ? (
          <line x1={0} x2={domainAudio(currentTime)} y1={13} y2={13} stroke={'white'} />
        ) : (
          <line x1={0} x2={170} y1={13} y2={13} stroke={'white'} />
        )}
        <line x1={0} x2={domainAudio(currentTime)} y1={13} y2={13} stroke={'white'} />
      </g>
    </svg>
  )
}
