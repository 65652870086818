import React, { useContext, useEffect, useRef, useState } from 'react'
import S from './TopBar.module.css'
import BurgerMenu from './HamburgerIcon.js'
import { useNavigate, useMatch } from 'react-router'
import closeMenu from '../assets/close-menu.svg'
import logoTtp from '../assets/logo-exp-ttp.svg'
import { scrollerReturnTo, ScrollerTools } from './Scroller'

function _TopBar({ jumpTo }, ref) {
  let navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const scroller = useContext(ScrollerTools)
  const about = useMatch('/about')
  const promises = useMatch('/violations')

  const entry1 = useRef()
  const entry2 = useRef()
  const entry3 = useRef()
  const entry4 = useRef()
  const entry5 = useRef()

  useEffect(() => {
    function animate() {
      if (!about && !promises) {
        const {
          jumpPoints: { current: jumpPoints },
        } = scroller
        if (entry1.current) {
          entry1.current.style.fontFamily =
            document.documentElement.scrollTop < jumpPoints['broken-promises']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry1.current.style.background =
            document.documentElement.scrollTop < jumpPoints['broken-promises'] ? '#EFF3FE' : 'none'
        }
        if (entry2.current) {
          entry2.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['broken-promises'] &&
            document.documentElement.scrollTop < jumpPoints['the-wall']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry2.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['broken-promises'] &&
            document.documentElement.scrollTop < jumpPoints['the-wall']
              ? '#EFF3FE'
              : 'none'
        }
        if (entry3.current) {
          entry3.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['the-wall'] &&
            document.documentElement.scrollTop < jumpPoints['facebook-response']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry3.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['the-wall'] &&
            document.documentElement.scrollTop < jumpPoints['facebook-response']
              ? '#EFF3FE'
              : 'none'
        }
        if (entry4.current) {
          entry4.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['facebook-response'] &&
            document.documentElement.scrollTop < jumpPoints['repository']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry4.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['facebook-response'] &&
            document.documentElement.scrollTop < jumpPoints['repository']
              ? '#EFF3FE'
              : 'none'
        }
        if (entry5.current) {
          entry5.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['repository']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry5.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['repository'] ? '#EFF3FE' : 'none'
        }
        requestAnimationFrame(animate)
      }
    }
    requestAnimationFrame(animate)
  }, [scroller])

  return (
    <div className={S.TopBarMobile} ref={ref}>
      <a href="https://www.techtransparencyproject.org/" className={S.BlockLogo} target={'_blank'}>
        <img src={logoTtp} />
      </a>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className={S.TitleMobile} onClick={() => navigate('/')}>
          Broken Promises
        </div>
        {menuOpen ? (
          <img onClick={() => setMenuOpen(!menuOpen)} src={closeMenu} />
        ) : (
          <div onClick={() => setMenuOpen(!menuOpen)}>
            <BurgerMenu />
          </div>
        )}
      </div>
      {menuOpen && (
        <div className={S.MenuMobileOpen}>
          <div className={S.BodyMenuMobile}>
            <div
              ref={entry1}
              className={S.ItemMenu}
              onClick={() => {
                if (about || promises) {
                  scrollerReturnTo('intro')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('intro')
                }
              }}
            >
              <span className={S.ItemMenuTitle}>Introduction</span>
            </div>
            <div
              ref={entry2}
              className={S.ItemMenu}
              onClick={() => {
                if (about || promises) {
                  scrollerReturnTo('broken-promises')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('broken-promises')
                }
              }}
            >
              <span className={S.ItemMenuSup}>Section I</span>
              <span className={S.ItemMenuTitle}>Broken Promises</span>
            </div>
            <div
              ref={entry3}
              className={S.ItemMenu}
              onClick={() => {
                if (about || promises) {
                  scrollerReturnTo('the-wall')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('the-wall')
                }
              }}
            >
              <span className={S.ItemMenuTitle}>The Wall</span>
            </div>
            <div
              ref={entry4}
              className={S.ItemMenu}
              onClick={() => {
                if (about || promises) {
                  scrollerReturnTo('facebook-response')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('facebook-response')
                }
              }}
            >
              <span className={S.ItemMenuSup}>Section II</span>
              <span className={S.ItemMenuTitle}>Facebook's response</span>
            </div>
            <div
              ref={entry5}
              className={S.ItemMenu}
              onClick={() => {
                if (about || promises) {
                  scrollerReturnTo('repository')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('repository')
                }
              }}
            >
              <span className={S.ItemMenuTitle}>Dive deeper</span>
            </div>
            <hr className={S.HrAboutMobile} />
            <div
              style={{
                background: about ? '#EFF3FE' : 'none',
                fontFamily: about ? 'Atype-Medium' : 'Atype-Regular',
              }}
              className={S.ItemMenu}
              onClick={() => navigate('/about')}
            >
              <span className={S.ItemMenuTitle}>About the project</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export const TopBar = React.forwardRef(_TopBar)
