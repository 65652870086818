
import React from 'react'
import S from './IntroLegend.module.css'
import { COLORS } from '../utils'
import { useNavigate } from 'react-router'

function _IntroLegend({ style }, ref) {
  const navigate = useNavigate()
  return (
    <div style={style}  ref={ref}>
      <div className={S.Legend}>
        {Object.keys(COLORS)
        .filter(key => key !== 'None')
        .map((topic) => (
          <div className={S.ItemLegend} key={topic}>
            <div className={S.RectLegend} style={{ background: COLORS[topic] }}></div>
            <div className={S.NameItemLegend}>{topic}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const IntroLegend = React.forwardRef(_IntroLegend)
