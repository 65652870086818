/* eslint-disable react/jsx-max-props-per-line */
import React, { useRef } from 'react'

export function WallAnchor({ scrollId, animation }) {
  const pageRef = useRef()
  const viewportHeight = window.innerHeight

  return (
    <div
    style={{ width: '100vw', height: '1px', position: 'relative' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="the-wall"
      data-jump-displacement={`-${viewportHeight}`}
      ref={pageRef}
    >
    </div>
  )
}
