import React from 'react'
import S from './Legend.module.css'
import { COLORS } from '../utils'
import { useNavigate } from 'react-router'
import { persistScroll } from './Scroller'

function _Legend({ style, setOpenLegend }, ref) {
  const navigate = useNavigate()
  return (
    <div style={style} className={S.Legend} ref={ref}>
      <div className={S.TopLegend}>
        <div className={S.HowToRead}>How to read it</div>
      </div>
      <div>
        <div className={S.Shape}>
          Each tile represents a violation and color represents the event type
        </div>
      </div>
      <div style={{ borderBottom: '1px solid #E4E4E7', paddingBottom: 10 }}>
        {Object.keys(COLORS).map((topic) => (
          <div className={S.ItemLegend} key={topic}>
            <div className={S.RectLegend} style={{ background: COLORS[topic] }}></div>
            <div className={S.NameItemLegend}>{topic}</div>
          </div>
        ))}
      </div>
      <div className={S.InfoText}>
        <span
          className={S.Methodology}
          onClick={() => {
            persistScroll()
            navigate('/about')
          }}
        >
          Go to the Methodology
        </span>{' '}
        to see how we categorized the incidents into event types.
      </div>
    </div>
  )
}

export const Legend = React.forwardRef(_Legend)
