import { scaleLinear } from 'd3-scale'
import React from 'react'

export default function Wave({ playing, currentTime, duration }) {

  const domainAudio = scaleLinear().domain([0, duration]).range([0, 203])

  return (
    <svg
      width="203"
      height="26"
      viewBox="0 0 203 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <line
          opacity={playing ? 1 : 0.5}
          x1="1.13428"
          y1="4"
          x2="1.13428"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="4.98047"
          y1="8"
          x2="4.98047"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="8.8269"
          y1="8"
          x2="8.8269"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="12.6729"
          y1="3"
          x2="12.6729"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="16.519"
          y1="2"
          x2="16.519"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="20.3652"
          y1="6"
          x2="20.3652"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="24.2109"
          y1="7"
          x2="24.2109"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="28.0574"
          y1="10"
          x2="28.0574"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="31.9038"
          y1="5"
          x2="31.9038"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="35.7495"
          y1="7"
          x2="35.7495"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="39.5957"
          y1="6"
          x2="39.5957"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="43.4419"
          y1="5"
          x2="43.4419"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="47.2886"
          y1="3"
          x2="47.2886"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="51.1343"
          y1="2"
          x2="51.1343"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="54.9805"
          y1="9"
          x2="54.9805"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="58.8267"
          y1="4"
          x2="58.8267"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="62.6724"
          y1="9"
          x2="62.6724"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="66.519"
          y1="11"
          x2="66.519"
          y2="14"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="70.3652"
          y1="2"
          x2="70.3652"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="74.2109"
          y1="1"
          x2="74.2109"
          y2="25"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="78.0571"
          y1="11"
          x2="78.0571"
          y2="14"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="81.9036"
          y1="9"
          x2="81.9036"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="85.75"
          y1="8"
          x2="85.75"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="89.5957"
          y1="5"
          x2="89.5957"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="93.4417"
          y1="2"
          x2="93.4417"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="97.2883"
          y1="8"
          x2="97.2883"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="101.134"
          y1="4"
          x2="101.134"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="104.98"
          y1="4"
          x2="104.98"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="108.826"
          y1="9"
          x2="108.826"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="112.673"
          y1="2"
          x2="112.673"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="116.519"
          y1="6"
          x2="116.519"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="120.365"
          y1="7"
          x2="120.365"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="124.211"
          y1="7"
          x2="124.211"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="128.057"
          y1="5"
          x2="128.057"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="131.904"
          y1="2"
          x2="131.904"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="135.75"
          y1="7"
          x2="135.75"
          y2="19"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="139.595"
          y1="3"
          x2="139.595"
          y2="23"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="143.442"
          y1="8"
          x2="143.442"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="147.288"
          y1="4"
          x2="147.288"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="151.135"
          y1="8"
          x2="151.135"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="154.98"
          y1="10"
          x2="154.98"
          y2="15"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="158.826"
          y1="10"
          x2="158.826"
          y2="16"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="162.673"
          y1="4"
          x2="162.673"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="166.518"
          y1="3"
          x2="166.518"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="170.365"
          y1="9"
          x2="170.365"
          y2="17"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="174.211"
          y1="1"
          x2="174.211"
          y2="24"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="178.057"
          y1="5"
          x2="178.057"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="181.903"
          y1="7"
          x2="181.903"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="185.75"
          y1="7"
          x2="185.75"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="189.596"
          y1="6"
          x2="189.596"
          y2="20"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="193.442"
          y1="4"
          x2="193.442"
          y2="22"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="197.288"
          y1="8"
          x2="197.288"
          y2="18"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          opacity={playing ? 1 : 0.5}
          x1="201.134"
          y1="4"
          x2="201.134"
          y2="21"
          stroke={playing ? 'orange' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
        />
        {currentTime < duration ? (
          <line x1={0} x2={domainAudio(currentTime)} y1={13} y2={13} stroke={'white'} />
        ) : (
          <line x1={0} x2={203} y1={13} y2={13} stroke={'white'} />
        )}
        <line x1={0} x2={domainAudio(currentTime)} y1={13} y2={13} stroke={'white'} />
      </g>
    </svg>
  )
}
