import React from 'react'
import S from './Legend.module.css'
import closeLegendIcon from '../assets/close-legend-mobile.svg'
import { COLORS } from '../utils'
import { persistScroll } from './Scroller'
import { useNavigate } from 'react-router'

function _LegendMobile({ style, setOpenLegend }, ref) {
  const navigate = useNavigate()
  return (
    <div style={style} className={S.LegendMobile} ref={ref}>
      <div className={S.CloseLegend}>
        <div
          onClick={() => {
            setOpenLegend(false)
            document.getElementsByTagName('body')[0].style.overflow = 'visible'
          }}
        >
          <img src={closeLegendIcon} />
        </div>
        <div className={S.TitleLegendMobile}>
          <div className={S.HowToReadMobile}>How to read it</div>
        </div>
      </div>
      <div className={S.ContentBox}>
        <div>
          <div className={S.ShapeMobile}>
            Each tile represents a violation and color represents the event type
          </div>
        </div>
        <div style={{ borderBottom: '1px solid #E4E4E7', paddingBottom: 10 }}>
          {Object.keys(COLORS).map((topic) => (
            <div className={S.ItemLegend} key={topic}>
              <div className={S.RectLegendMobile} style={{ background: COLORS[topic] }}></div>
              <div className={S.NameItemLegend}>{topic}</div>
            </div>
          ))}
        </div>
        <div className={S.InfoTextMobile}>
          <span
            className={S.Methodology}
            onClick={() => {
              persistScroll()
              navigate('/about')
            }}
          >
            Go to the Methodology
          </span>{' '}
          to see how we categorized the incidents into event types.
        </div>
      </div>
    </div>
  )
}

export const LegendMobile = React.forwardRef(_LegendMobile)
