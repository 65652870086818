import React from 'react'
import * as prismic from '@prismicio/client'
import { apiEndpoint, accessToken, linkResolver, hrefResolver } from './prismicConfiguration'

export const COLORS = {
  'Abuse and Nudity': '#FF3D60',
  'Alcohol and Drugs': '#FF3DEC',
  'Animal Crimes': '#FFB8F8',
  'Bullying and Harassment': '#FFEA00',
  'Criminal Activity': '#FF7B00',
  Firearms: '#CCFF33',
  'Fraud and Deception': '#00FFF0',
  'Harm Against Property': '#FFECAA',
  'Hate Speech': '#00F982',
  'Historical Artifacts': '#A0001C',
  'Manipulated Media': '#BC00DD',
  'Privacy and Image Rights': '#008000',
  'Regulated Goods': '#A073FF',
  'Suicide and Self-Injury': '#000AFF',
  'Violence and Incitement': '#00A3FF',
  'Violent and Graphic Content': '#6A00F4',
  None: '#9EB5D9',
}

export const GREYS = {
  'Abuse and Nudity': '#8C8C8C',
  'Alcohol and Drugs': '#979797',
  'Animal Crimes': '#CECFCE',
  'Bullying and Harassment': '#E8E8E8',
  'Criminal Activity': '#A3A2A3',
  Firearms: '#EEEDED',
  'Fraud and Deception': '#E4E3E4',
  'Harm Against Property': '#EDECED',
  'Hate Speech': '#D8DAD9',
  'Historical Artifacts': '#727172',
  'Manipulated Media': '#6E6E6E',
  'Privacy and Image Rights': '#6C6C6C',
  'Regulated Goods': '#8B8C8C',
  'Suicide and Self-Injury': '#6B6B6B',
  'Violence and Incitement': '#999999',
  'Violent and Graphic Content': '#525252',
  None: '#B2B2B3',
}

export const DISABLED = {
  'Abuse and Nudity': '#FFD8DF',
  'Alcohol and Drugs': '#FFD8FB',
  'Animal Crimes': '#FFF1FE',
  'Bullying and Harassment': '#FFFBCC',
  'Criminal Activity': '#FFE5CC',
  Firearms: '#F5FFD6',
  'Fraud and Deception': '#CCFFFC',
  'Harm Against Property': '#FFFBEE',
  'Hate Speech': '#CCFEE6',
  'Historical Artifacts': '#ECCCD2',
  'Manipulated Media': '#F2CCF8',
  'Privacy and Image Rights': '#CCE6CC',
  'Regulated Goods': '#ECE3FF',
  'Suicide and Self-Injury': '#CCCEFF',
  'Violence and Incitement': '#CCEDFF',
  'Violent and Graphic Content': '#E1CCFD',
  None: '#DFE7F7',
}

export function eventTypeToColor(eventType) {
  return COLORS[eventType] ?? 'black'
}

export function eventTypeToGray(eventType) {
  return GREYS[eventType] ?? 'black'
}

export function eventTypeToDisabled(eventType) {
  return DISABLED[eventType] ?? 'black'
}

export function eventTypesToColors(eventTypesCSV) {
  return eventTypesCSV.split(',').map((s) => eventTypeToColor(s.trim()))
}

export function getBackground(event_type) {
  const colors = eventTypesToColors(event_type)
  const background =
    typeof colors === 'string'
      ? colors
      : Array.isArray(colors) && colors.length === 1
      ? colors[0]
      : `linear-gradient(${colors.join(', ')})`
  return background
}

export function eventTypesToGrayScale(eventTypesCSV) {
  return eventTypesCSV.split(',').map((s) => eventTypeToGray(s.trim()))
}

export function eventTypesToDisabledScale(eventTypesCSV) {
  return eventTypesCSV.split(',').map((s) => eventTypeToDisabled(s.trim()))
}

export function eventTypesToHateScale(eventTypesCSV) {
  return eventTypesCSV
    .split(',')
    .map((s) => s.trim())
    .map((s) => {
      if (s !== 'Hate Speech') {
        return eventTypeToGray(s)
      } else {
        return eventTypeToColor(s)
      }
    })
}

export function dateFormatter(date) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const dateToParse = new Date(date)
  return dateToParse.toLocaleDateString('en-US', options)
}

export const IDS_36 = [
  9, 14, 15, 17, 18, 32, 34, 35, 72, 104, 108, 114, 126, 128, 138, 149, 154, 155, 161, 178, 188,
  190, 204, 251, 261, 276, 285, 293, 315, 345, 362, 365, 366, 383, 400, 410,
]

const createClientOptions = (req = null, prismicAccessToken = null) => {
  const reqOption = req ? { req } : {}
  const accessTokenOption = prismicAccessToken ? { accessToken: prismicAccessToken } : {}
  return {
    ...reqOption,
    ...accessTokenOption,
  }
}

export const Client = (req = null) =>
  prismic.createClient(apiEndpoint, createClientOptions(req, accessToken))
