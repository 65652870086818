import React, { useContext, useEffect, useRef, useState } from 'react'
import S from './TopBar.module.css'
import BurgerMenu from './HamburgerIcon.js'
import { useMatch, useNavigate } from 'react-router'
import closeMenu from '../assets/close-menu.svg'
import logoTtp from '../assets/logo-exp-ttp.svg'
import { ReactComponent as TwitterSVG } from '../assets/icon_twitter.svg'
import { ReactComponent as MailSVG } from '../assets/icon_email.svg'
import { scrollerReturnTo, ScrollerTools } from './Scroller'

function _TopBarDesktop({ jumpTo }, ref) {
  let navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const scroller = useContext(ScrollerTools)
  const about = useMatch('/about')

  const entry1 = useRef()
  const entry2 = useRef()
  const entry3 = useRef()
  const entry4 = useRef()
  const entry5 = useRef()
  
  useEffect(() => {
    function animate() {
      if (!about) {
        const {
          jumpPoints: { current: jumpPoints },
        } = scroller
        if (entry1.current) {
          entry1.current.style.fontFamily =
            document.documentElement.scrollTop < jumpPoints['broken-promises']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry1.current.style.background =
            document.documentElement.scrollTop < jumpPoints['broken-promises'] ? '#EFF3FE' : 'none'
        }
        if (entry2.current) {
          entry2.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['broken-promises'] &&
            document.documentElement.scrollTop < jumpPoints['the-wall']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry2.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['broken-promises'] &&
            document.documentElement.scrollTop < jumpPoints['the-wall']
              ? '#EFF3FE'
              : 'none'
        }
        if (entry3.current) {
          entry3.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['the-wall'] &&
            document.documentElement.scrollTop < jumpPoints['facebook-response']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry3.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['the-wall'] &&
            document.documentElement.scrollTop < jumpPoints['facebook-response']
              ? '#EFF3FE'
              : 'none'
        }
        if (entry4.current) {
          entry4.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['facebook-response'] &&
            document.documentElement.scrollTop < jumpPoints['repository']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry4.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['facebook-response'] &&
            document.documentElement.scrollTop < jumpPoints['repository']
              ? '#EFF3FE'
              : 'none'
        }
        if (entry5.current) {
          entry5.current.style.fontFamily =
            document.documentElement.scrollTop >= jumpPoints['repository']
              ? 'Atype-Medium'
              : 'Atype-Regular'
          entry5.current.style.background =
            document.documentElement.scrollTop >= jumpPoints['repository'] ? '#EFF3FE' : 'none'
        }
        requestAnimationFrame(animate)
      }
    }
    requestAnimationFrame(animate)
  }, [scroller])

  return (
    <div className={S.TopBar} ref={ref}>
      <div className={S.BlockLogo}>
        <a href="https://www.techtransparencyproject.org/" target={'_blank'} className={S.LogoTTP}>
          <img src={logoTtp} />
        </a>
      </div>
      <div className={S.BurgerMenu} onClick={() => setMenuOpen(!menuOpen)}>
        <div className={S.TitleDesktop} onClick={() => navigate('/')}>
          Broken Promises
        </div>
        {menuOpen ? <img src={closeMenu} /> : <BurgerMenu />}
      </div>
      {menuOpen && (
        <div className={S.MenuDesktopOpen}>
          <div className={S.BodyMenuDesktop}>
            <div
              ref={entry1}
              className={S.ItemMenuDesktop}
              onClick={() => {
                if (about) {
                  scrollerReturnTo('intro')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('intro')
                }
              }}
            >
              <span className={S.ItemMenuTitleDesktop}>Introduction</span>
            </div>
            <div
              ref={entry2}
              className={S.ItemMenuDesktop}
              onClick={() => {
                if (about) {
                  scrollerReturnTo('broken-promises')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('broken-promises')
                }
              }}
            >
              <span className={S.ItemMenuSupDesktop}>Section I</span>
              <span className={S.ItemMenuTitleDesktop}>Broken Promises</span>
            </div>

            <div
              ref={entry3}
              className={S.ItemMenuDesktop}
              onClick={() => {
                if (about) {
                  scrollerReturnTo('the-wall')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('the-wall')
                }
              }}
            >
              <span className={S.ItemMenuTitleDesktop}>The Wall</span>
            </div>

            <div
              ref={entry4}
              className={S.ItemMenuDesktop}
              onClick={() => {
                if (about) {
                  scrollerReturnTo('facebook-response')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('facebook-response')
                }
              }}
            >
              <span className={S.ItemMenuSupDesktop}>Section II</span>
              <span className={S.ItemMenuTitleDesktop}>Facebook's response</span>
            </div>
            <div
              ref={entry5}
              className={S.ItemMenuDesktop}
              onClick={() => {
                if (about) {
                  scrollerReturnTo('repository')
                  navigate('/')
                } else {
                  setMenuOpen(false)
                  scroller.jumpTo('repository')
                }
              }}
            >
              <span className={S.ItemMenuTitleDesktop}>Dive deeper</span>
            </div>
            <hr className={S.HrAbout} />
            <div
              style={{
                background: about ? '#EFF3FE' : 'none',
                fontFamily: about ? 'Atype-Medium' : 'Atype-Regular',
              }}
              className={S.ItemMenuDesktop}
              onClick={() => navigate('/about')}
            >
              <span className={S.ItemMenuTitleDesktop}>About the project</span>
            </div>
            <div className={S.FooterMenu}>
              <div className={S.FooterTitle}>Tech Transparency Project</div>
              <div className={S.SocialIcons}>
                  <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                    <a href="https://twitter.com/ttp_updates" target={'_blank'}>
                      <TwitterSVG />
                    </a>
                    <a href="mailto:info@techtransparencyproject.org">
                      <MailSVG />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export const TopBarDesktop = React.forwardRef(_TopBarDesktop)
