function AnimationKind1(progress, tileWidth, tileHeight, tileTop, tileLeft, background) {
  /**
   * `progress` = animation progress for the tile, from 0 to 1
   * `tileWidth` = default tile width (number, in px)
   * `tileHeight` = default tile height (number, in px)
   * `tileTop` = default vertical position of the tile in the wall (number, in px)
   * `tileLeft` = default horizontal position of the tile in the wall (number, in px)
   * `background` = single color #RRGGBB or array of colors
   * Please return a style object, do not append units (props are used to draw on a canvas)
   * Available properties: width, height, top, left, opacity, background
   * Use other properties at your own risk
   */
  if (progress === 0) {
    return { opacity: 0 }
  }
  if (progress < 0.25) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 0.5) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else if (progress < 0.75) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 1) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else {
    return {
      /* use defaults, i.e. tile fully rendered */
    }
  }
}

function AnimationKind2(progress, tileWidth, tileHeight, tileTop, tileLeft, background) {
  if (progress === 0) {
    return { opacity: 0 }
  }
  if (progress < 0.25) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 0.5) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else if (progress < 0.75) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 1) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else {
    return {
      /* use defaults, i.e. tile fully rendered */
    }
  }
}

function AnimationKind3(progress, tileWidth, tileHeight, tileTop, tileLeft, background) {
  if (progress === 0) {
    return { opacity: 0 }
  }
  if (progress < 0.25) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 0.5) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else if (progress < 0.75) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 1) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else {
    return {
      /* use defaults, i.e. tile fully rendered */
    }
  }
}

function AnimationKind4(progress, tileWidth, tileHeight, tileTop, tileLeft, background) {
  if (progress === 0) {
    return { opacity: 0 }
  }
  if (progress < 0.25) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 0.5) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else if (progress < 0.75) {
    return {
      height: tileHeight / 2,
      top: tileTop,
    }
  } else if (progress < 1) {
    return {
      height: tileHeight / 2,
      top: tileTop + tileHeight / 2,
    }
  } else {
    return {
      /* use defaults, i.e. tile fully rendered */
    }
  }
}

export const BUILD_WALL_ANIMATIONS = [
  AnimationKind1,
  AnimationKind2,
  AnimationKind3,
  AnimationKind4,
]
