import { useEffect, useRef } from 'react'
import { registerAnimator } from './useAnimateEngine'

export function useAnimatedStyle(domRef, styleInterpolator) {
  const styleInterpolatorFn = useRef(styleInterpolator)
  const scrollerRef = useRef(0)
  useEffect(() => {
    styleInterpolatorFn.current = styleInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRef.current && typeof styleInterpolatorFn.current === 'function') {
        const styleObject = styleInterpolatorFn.current({ node: domRef.current })
        if (styleObject) {
          // eslint-disable-next-line no-unused-vars
          for (const key in styleObject) {
            if (key === 'scrollX') {
              if (styleObject[key] !== scrollerRef.current) {
                domRef.current.scrollTo(styleObject[key], 0)
                scrollerRef.current = styleObject[key]
              }
            } else {
              domRef.current.style[key] = styleObject[key]
            }
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRef])
}

export function useAnimatedStyleVectorStatic(domRefParent, styleInterpolator, preInterpolator = undefined) {
  const styleInterpolatorFn = useRef(styleInterpolator)
  useEffect(() => {
    styleInterpolatorFn.current = styleInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRefParent.current && typeof styleInterpolatorFn.current === 'function') {
        const children = domRefParent.current.children
        const preResult = typeof preInterpolator === "function" ? preInterpolator() : undefined
        if (preResult === null) {
          return
        } 
        for (let i = 0; i < children.length; i++) {
          const styleObject = styleInterpolatorFn.current(preResult, { index: i, last: i === children.length - 1 })
          if (styleObject) {
            for (const key in styleObject) {
              children[i].style[key] = styleObject[key]
            }
          } else {
            break
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRefParent])
}

export function useAnimatedStyleVector(domRefParent, styleInterpolator, preInterpolator = undefined) {
  const styleInterpolatorFn = useRef(styleInterpolator)
  useEffect(() => {
    styleInterpolatorFn.current = styleInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRefParent.current && typeof styleInterpolatorFn.current === 'function') {
        const children = domRefParent.current.children
        for (let i = 0; i < children.length; i++) {
          const styleObject = styleInterpolatorFn.current({ index: i, last: i === children.length - 1 })
          if (styleObject) {
            for (const key in styleObject) {
              children[i].style[key] = styleObject[key]
            }
          } else {
            break
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRefParent])
}

export function useAnimatedStyleSelector(domRefParent, selector, styleInterpolator) {
  const styleInterpolatorFn = useRef(styleInterpolator)
  useEffect(() => {
    styleInterpolatorFn.current = styleInterpolator
  })
  useEffect(() => {
    function animate() {
      if (domRefParent.current && typeof styleInterpolatorFn.current === 'function') {
        const children = domRefParent.current.querySelectorAll(selector)
        for (let i = 0; i < children.length; i++) {
          const styleObject = styleInterpolatorFn.current({ node: children[i] })
          if (styleObject) {
            for (const key in styleObject) {
              children[i].style[key] = styleObject[key]
            }
          }
        }
      }
    }
    return registerAnimator(animate)
  }, [domRefParent])
}
