import { fromPairs, groupBy, last, mapValues, reduce } from "lodash"
import { BUILD_WALL_ANIMATIONS } from "../buildWall"
import { eventTypesToColors, eventTypesToGrayScale, eventTypesToHateScale } from "../utils"
import dataset from "./dataset.json"

export const data = dataset.map((item) => ({
  ...item,
  year: item.date && parseInt(item.date.split('/')[2], 10),
  animationPreset: Math.floor(Math.random() * BUILD_WALL_ANIMATIONS.length),
  backgroundOriginal: eventTypesToColors(item.event_type),
  backgroundGray: eventTypesToGrayScale(item.event_type),
  backgroundHate: eventTypesToHateScale(item.event_type)
}))
export const countByYear = mapValues(groupBy(data, 'year'), (arr) => arr.length)
export const years = Object.keys(countByYear)
export const countByPrevYearCumulated = {}
let acc = 0
for(const [year, count] of Object.entries(countByYear)) {
  countByPrevYearCumulated[year] = acc
  acc += count
}

export const violenceTopic = dataset.filter((item) =>
  item.topic.includes('Violence and Criminal Behavior')
).length
export const objectableTopic = dataset.filter((item) =>
  item.topic.includes('Objectionable Content')
).length
export const safetyTopic = dataset.filter((item) => item.topic.includes('Safety')).length
export const abuseAndNudityTopic = dataset.filter((item) =>
  item.event_type.includes('Abuse and Nudity')
).length
export const bullyngAndHarassmentTopic = dataset.filter((item) =>
  item.event_type.includes('Bullying and Harassment')
).length
export const suicideAndSelfInjuryTopic = dataset.filter((item) =>
  item.event_type.includes('Suicide and Self-Injury')
).length

export const twoOrMore = dataset.filter((item) =>
  item.topic.includes(',')
).length