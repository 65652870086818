/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useGlitchAnimation } from '../../components/Glitch'
import { Message } from '../../components/Message'
import { Tile } from '../../components/Tile'
import { useAnimatedProps } from '../../hooks/useAnimatedProps'
import { useAnimatedStyle, useAnimatedStyleVector } from '../../hooks/useAnimatedStyle'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import { useFrame } from '../../hooks/useFrame'
import messages from '../../data/messages.json'
import {
  eventTypesToColors,
  eventTypesToDisabledScale,
  eventTypeToDisabled,
  IDS_36,
} from '../../utils'
import S from './S02_Wall.module.css'
import { useNavigate } from 'react-router'
import { Legend } from '../../components/Legend'
import { MessagePost } from '../../components/MessagePost'
import { zip } from 'lodash'
import { scaleLinear } from 'd3-scale'
import PromiseCard from '../../components/PromiseCard'
import { countByPrevYearCumulated, data } from '../../data'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import firstMp3 from '../../assets/mp3/2018.mp3'
import secondMp3 from '../../assets/mp3/2019.mp3'
import thirdMp3 from '../../assets/mp3/2020.mp3'
import fourthMp3 from '../../assets/mp3/2021.mp3'
import { ReactComponent as PauseIcon } from '../../assets/pause.svg'
import { ReactComponent as PlayIcon } from '../../assets/play.svg'

import {
  EXAMPLE_POST_1,
  EXAMPLE_POST_2,
  EXAMPLE_POST_3,
  EXAMPLE_POST_4,
  EXAMPLE_POST_5,
  POINTED_TILE,
} from '../../constants'
import { BUILD_WALL_ANIMATIONS } from '../../buildWall'
import { createPortal } from 'react-dom'
import Wave from '../../components/Wave'

const CIRCLE_PADDING = 20

const useAudio = (url) => {
  const [audio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(false)
  const [current, setCurrent] = useState(0)

  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    playing ? audio.play() : audio.pause()
  }, [playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    audio.addEventListener('timeupdate', () => setCurrent(audio.currentTime))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
      audio.removeEventListener('timeupdate', () => setCurrent(0))
    }
  }, [])

  return [playing, toggle, current, audio.duration]
}

function _SampleTileHighlight({ id }, ref) {
  const { width, height } = useFrame()

  const tileWidth = (width - 375) / 30
  const tileHeight = height / Math.ceil(data.length / 30)
  const circleWidth = tileWidth + CIRCLE_PADDING
  const circleHeight = tileHeight + CIRCLE_PADDING

  const circleSize = Math.max(circleWidth, circleHeight) * 1.2

  const left = (id % 30) * tileWidth + 375 - (circleSize - tileWidth) / 2
  const top =
    (height / Math.ceil(data.length / 30)) * Math.floor(id / 30) - (circleSize - tileHeight) / 2

  return (
    <div
      ref={ref}
      className={S.messageSample}
      style={{ left, top, width: circleSize, height: circleSize, borderRadius: circleSize / 2 }}
    >
      <Tile
        style={{
          width: tileWidth,
          height: tileHeight,
        }}
        colors={eventTypesToColors(data[id].event_type)}
      />
    </div>
  )
}

const SampleTileHighlight = React.forwardRef(_SampleTileHighlight)

function _AudioMessage({ caption, transcript, duration, source, year }, ref) {
  const [playing, toggle, currentTime, durationTime] = useAudio(source)
  return (
    <div className={S.audio} ref={ref}>
      <p className={S.audioHeader}>{caption}</p>
      <div className={S.audioTrack}>
        {playing ? (
          <PauseIcon style={{ width: 32, height: 32 }} onClick={toggle} />
        ) : (
          <PlayIcon style={{ width: 32, height: 32 }} onClick={toggle} />
        )}
        <div style={{ flexDirection: 'column', marginLeft: '16px' }}>
          <Wave currentTime={currentTime} duration={durationTime} playing={playing} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={S.audioDuration}>{duration}</div>
            <div className={S.audioYear}>{year}</div>
          </div>
        </div>
      </div>
      <p className={S.audioFooter}>{transcript}</p>
    </div>
  )
}

const AudioMessage = React.forwardRef(_AudioMessage)

function blendColors(colors1, colors2, alpha) {
  return zip(colors1, colors2).map(([color1, color2]) => {
    return scaleLinear().domain([0, 1000]).range([color1, color2]).clamp(true)(alpha)
  })
}

function appendUnits(buildWallResult) {
  if (buildWallResult.width && !buildWallResult.width.toString().endsWith('px')) {
    buildWallResult.width = buildWallResult.width + 'px'
  }
  if (buildWallResult.height && !buildWallResult.height.toString().endsWith('px')) {
    buildWallResult.height = buildWallResult.height + 'px'
  }
  if (buildWallResult.left && !buildWallResult.left.toString().endsWith('px')) {
    buildWallResult.left = buildWallResult.left + 'px'
  }
  if (buildWallResult.top && !buildWallResult.top.toString().endsWith('px')) {
    buildWallResult.top = buildWallResult.top + 'px'
  }
  return buildWallResult
}

export function Wall({ scrollId, progress, animation, jumpTo }) {
  const [promiseSelected, setPromise] = useState(null)
  const titleRef = useRef()
  const firstTileRef = useRef()
  const infoPillRef = useRef()
  const tilesRef = useRef()
  const yearSwatchContainerRef = useRef()
  const yearSwatchRef = useRef()
  const numberTilesRef = useRef()
  const messagesFirstRef = useRef()
  const messagesRef = useRef()
  const messagesSecondRef = useRef()
  const messagesViolenceRef = useRef()
  const messagesTwoOrMore = useRef()
  const messagesPostViolenceRef = useRef()
  const messagesObjectableRef = useRef()
  const messagesPostObjectableRef = useRef()
  const messagesPost36Ref = useRef()
  const messagesSafetyRef = useRef()
  const messages36Ref = useRef()
  const firstAudioRef = useRef()
  const messageViolenceSampleRef = useRef()
  const messageObjectableSampleRef = useRef()
  const messageSafetySampleRef = useRef()
  const message36SampleRef = useRef()
  const messagesPostSafetyRef = useRef()
  const audiosRef = useRef()
  const secondAudioRef = useRef()
  const thirdAudioRef = useRef()
  const fourthAudioRef = useRef()
  const audiosInnerRef = useRef()
  const refLegend = useRef()
  const promiseRef = useRef()
  const animCtx = useAnimationContext({ numTiles: data.length, promiseRef })
  const overlayRef = useRef()
  const scrollEmulationRef = useRef(null)
  const [hasClickedTile, setHasClickedTile] = useState(false)

  const { width, height } = useFrame()

  const navigate = useNavigate()

  useGlitchAnimation(animation.animations.glitch(progress, animCtx), progress)
  useAnimatedStyle(firstTileRef, animation.animations.tile_zero(progress, animCtx))
  useAnimatedStyle(titleRef, animation.animations.title(progress, animCtx))
  const tilesAnimator = useMemo(() => {
    return animation.animations.tile_n(progress, animCtx)
  }, [progress, animCtx])
  useAnimatedStyleVector(tilesRef, (...args) => {
    const aaa = tilesAnimator(...args)
    if (!aaa) {
      return null
    }
    const { stepFilter, intensity, ...steps } = aaa
    const { index } = args[0]
    const datum = data[index]
    const step = steps[`step${datum.year}`]
    const fullyVisible = Math.floor(step - 3)
    const partlyVisible = fullyVisible + 3
    let opacity = 0
    let pointerEvents = 'none'
    let tileWidth = (width - 375) / 30
    let tileHeight = Math.ceil(height / Math.ceil(data.length / 30))
    let tileLeft = ((index % 30) * (width - 375)) / 30
    let tileTop = (height / Math.ceil(data.length / 30)) * Math.floor(index / 30)
    const backgroundIntense = animCtx.current.promiseRef.current
      ? eventTypesToDisabledScale(datum.event_type)
      : eventTypesToColors(datum.event_type)
    const backgroundLight = eventTypesToDisabledScale(datum.event_type)
    const bg = blendColors(backgroundLight, backgroundIntense, intensity ?? 0)
    if (stepFilter === 0) {
      const delegate = BUILD_WALL_ANIMATIONS[datum.animationPreset]
      let ax
      const ix = index - countByPrevYearCumulated[datum.year]
      if (ix < fullyVisible) {
        ax = delegate(1, tileWidth, tileHeight, tileTop, tileLeft, bg)
      } else if (ix < partlyVisible) {
        ax = delegate((step - ix) / 3, tileWidth, tileHeight, tileTop, tileLeft, bg)
      } else {
        ax = delegate(0, tileWidth, tileHeight, tileTop, tileLeft, bg)
      }
      if (ax.left !== undefined) {
        ax.left += parseInt(ax.left) + 375 + 'px'
      }
      ax = appendUnits(ax)
      return {
        width: tileWidth + 'px',
        height: tileHeight + 'px',
        left: tileLeft + 375 + 'px',
        top: tileTop + 'px',
        'pointer-events': 'none',
        opacity: 1,
        background: bg.length === 1 ? bg : `linear-gradient(${bg.join(',')})`,
        ...ax,
      }
    } else {
      const isViolence = datum.topic.includes('Violence and Criminal Behavior')
      const isSafety = datum.topic.includes('Safety')
      const isObjectableContent = datum.topic.includes('Objectionable Content')
      const isTwoOrMore = datum.topic.includes(',')
      const isMessage36 = IDS_36.includes(datum.ID)
      if (stepFilter <= 1000) {
        if (isViolence) {
          opacity = 1
          pointerEvents = 'none'
        } else {
          opacity = 1 - stepFilter / 1000
          pointerEvents = 'none'
        }
      } else if (stepFilter > 1000 && stepFilter <= 2000) {
        if (isObjectableContent && isViolence) {
          opacity = 1
          pointerEvents = 'none'
        } else if (isObjectableContent && !isViolence) {
          opacity = (stepFilter - 1000) / 1000
          pointerEvents = 'none'
        } else if (!isObjectableContent && isViolence) {
          opacity = 1 - (stepFilter - 1000) / 1000
          pointerEvents = 'none'
        } else {
          opacity = 0
          pointerEvents = 'none'
        }
      } else if (stepFilter > 2000 && stepFilter <= 3000) {
        if (isSafety && isObjectableContent) {
          opacity = 1
          pointerEvents = 'none'
        } else if (isSafety && !isObjectableContent) {
          opacity = (stepFilter - 2000) / 1000
          pointerEvents = 'none'
        } else if (!isSafety && isObjectableContent) {
          opacity = 1 - (stepFilter - 2000) / 1000
          pointerEvents = 'none'
        } else {
          opacity = 0
          pointerEvents = 'none'
        }
      } else if (stepFilter > 3000 && stepFilter <= 4000) {
        if (isMessage36 && isSafety) {
          opacity = 1
          pointerEvents = 'none'
        } else if (isMessage36 && !isSafety) {
          opacity = (stepFilter - 3000) / 1000
          pointerEvents = 'none'
        } else if (!isMessage36 && isSafety) {
          opacity = 1 - (stepFilter - 3000) / 1000
          pointerEvents = 'none'
        } else {
          opacity = 0
          pointerEvents = 'none'
        }
      } else if (stepFilter > 4000 && stepFilter <= 5000) {
        if (isTwoOrMore && isMessage36) {
          opacity = 1
          pointerEvents = 'none'
        } else if (isTwoOrMore && !isMessage36) {
          opacity = (stepFilter - 4000) / 1000
          pointerEvents = 'none'
        } else if (!isTwoOrMore && isMessage36) {
          opacity = 1 - (stepFilter - 4000) / 1000
          pointerEvents = 'none'
        } else {
          opacity = 0
          pointerEvents = 'none'
        }
      } else if (stepFilter > 5000 && stepFilter <= 6000) {
        if (isTwoOrMore) {
          opacity = 1
          pointerEvents = 'auto'
        } else if (!isTwoOrMore) {
          opacity = (stepFilter - 5000) / 1000
          pointerEvents = 'auto'
        }
      } else {
        opacity = 1
        pointerEvents = 'auto'
      }
    }
    return {
      width: tileWidth + 'px',
      height: tileHeight + 'px',
      left: tileLeft + 375 + 'px',
      top: tileTop + 'px',
      opacity: opacity,
      'pointer-events': pointerEvents,
      background: bg.length === 1 ? bg : `linear-gradient(${bg.join(',')})`,
    }
  })
  const tileNumberAnimator = useMemo(() => {
    return animation.animations.number_tiles(progress, animCtx)
  }, [progress, animCtx])
  const yearSwatchAnimator = useMemo(() => {
    return animation.animations.year_swatch(progress, animCtx)
  }, [progress, animCtx])

  useAnimatedStyle(
    yearSwatchContainerRef,
    animation.animations.year_swatch_container(progress, animCtx)
  )
  useAnimatedStyle(yearSwatchRef, animation.animations.year_swatch_style(progress, animCtx))
  useAnimatedProps(yearSwatchRef, (...args) => {
    const aaa = yearSwatchAnimator(...args)
    if (!aaa) {
      return null
    }
    const { textContent } = aaa
    return { textContent: Math.floor(textContent).toString(10) }
  })
  useAnimatedStyle(infoPillRef, animation.animations.info_pill(progress, animCtx))
  useAnimatedProps(numberTilesRef, (...args) => {
    const aaa = tileNumberAnimator(...args)
    if (!aaa) {
      return null
    }
    const { textContent } = aaa
    return { textContent: Math.floor(textContent).toString(10) }
  })
  useAnimatedStyle(numberTilesRef, animation.animations.number_tiles_style(progress, animCtx))
  useAnimatedStyle(messagesFirstRef, animation.animations.messages_first(progress, animCtx))
  useAnimatedStyle(messagesSecondRef, animation.animations.messages_second(progress, animCtx))
  useAnimatedStyle(messagesViolenceRef, animation.animations.messages_violence(progress, animCtx))
  useAnimatedStyle(messages36Ref, animation.animations.messages_36(progress, animCtx))
  useAnimatedStyle(messagesTwoOrMore, animation.animations.messages_two_or_more(progress, animCtx))
  useAnimatedStyle(
    messagesPostViolenceRef,
    animation.animations.messages_violence_post(progress, animCtx)
  )
  useAnimatedStyle(
    messagesObjectableRef,
    animation.animations.messages_objectable(progress, animCtx)
  )
  useAnimatedStyle(
    messagesPostObjectableRef,
    animation.animations.messages_objectable_post(progress, animCtx)
  )
  useAnimatedStyle(messagesSafetyRef, animation.animations.messages_safety(progress, animCtx))
  useAnimatedStyle(
    messagesPostSafetyRef,
    animation.animations.messages_safety_post(progress, animCtx)
  )
  useAnimatedStyle(messagesPost36Ref, animation.animations.messages_36_post(progress, animCtx))
  useAnimatedStyle(refLegend, animation.animations.legend_style(progress, animCtx))

  useAnimatedStyle(
    messageViolenceSampleRef,
    animation.animations.violence_sample(progress, animCtx)
  )
  useAnimatedStyle(
    messageObjectableSampleRef,
    animation.animations.objectable_sample(progress, animCtx)
  )
  useAnimatedStyle(messageSafetySampleRef, animation.animations.safety_sample(progress, animCtx))
  useAnimatedStyle(message36SampleRef, animation.animations.messages_36_sample(progress, animCtx))

  useAnimatedStyle(audiosRef, animation.animations.audios(progress, animCtx))
  useAnimatedStyle(firstAudioRef, animation.animations.audio_1(progress, animCtx))
  useAnimatedStyle(secondAudioRef, animation.animations.audio_2(progress, animCtx))
  useAnimatedStyle(thirdAudioRef, animation.animations.audio_3(progress, animCtx))
  useAnimatedStyle(fourthAudioRef, animation.animations.audio_4(progress, animCtx))
  useAnimatedStyle(overlayRef, animation.animations.overlay(progress, animCtx))

  const tileWidth = (width - 375) / 30
  const tileHeightNatural = height / Math.ceil(data.length / 30)
  const tileHeight = Math.ceil(tileHeightNatural)

  return (
    <div
      style={{ height: '100vh' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="broken-promises"
      data-jump-displacement="200"
    >
      <div className="menu-placeholder-desktop" />
      <div className={S.wall}>
        <div className={S.part} ref={titleRef}>
          <p className={S.section}>Section I</p>
          <div className={S.title}>Broken Promises_</div>
        </div>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
          <Tile
            ref={firstTileRef}
            onClick={() => {
              setPromise(-1)
              promiseRef.current = false
            }}
            style={{ width: width, height: height, position: 'absolute' }}
            colors={eventTypesToColors(data[EXAMPLE_POST_1 - 1].event_type)}
          />
          <div ref={tilesRef}>
            {data.map((datum, i) => {
              return (
                <Tile
                  key={i}
                  onClick={() => {
                    setPromise(i)
                    setHasClickedTile(true)
                    promiseRef.current = true
                  }}
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                  }}
                  colors={eventTypesToColors(datum.event_type)}
                />
              )
            })}
          </div>
        </div>
        <div ref={infoPillRef} className={S.InfoPill}>
          <div className={S.yearSwatchContainer} ref={yearSwatchContainerRef}>
            <div className={S.yearSwatchLabel}>year</div>
            <div className={S.yearSwatch} ref={yearSwatchRef}></div>
          </div>
          <div className={S.numberTilesContainer}>
            <div className={S.numberTilesLabel}>violations</div>
            <div className={S.numberTiles} ref={numberTilesRef}></div>
          </div>
        </div>
        <div ref={messagesFirstRef} className={S.messages} style={{ bottom: 0 }}>
          <Message style={{ width: 182 }} body={messages[0]} />
          <Message style={{ width: 250 }} body={messages[1]} />
          <MessagePost
            style={{ width: 210 }}
            promiseId={EXAMPLE_POST_1}
            className={S.messagePost}
            isFirstMessage
          />
          <Message style={{ width: 278, marginTop: 30 }} body={messages[2]} />
          <Message style={{ width: 285, marginBottom: 30 }} body={messages[3]} />
          <div className={S.scrollArrow}>
            Scroll to load a database <br />
            of major offenses.
          </div>
          <div className={S.arrowDiv}>
            <div className={S.arrowEdge} />
          </div>
        </div>
        <div ref={messagesSecondRef} className={S.messages}>
          <Message style={{ width: 280 }} body={messages[4]} />
        </div>
        <div ref={messagesViolenceRef} className={S.messages}>
          <Message style={{ width: 250 }} body={messages[5]} />
          <Message
            style={{
              width: 260,
              left: 18,
              marginBottom: 50,
              backgroundColor: '#485475',
              color: '#FFF',
              marginRight: 79,
              borderRadius: '8px 8px 8px 1px',
            }}
            body={messages[6]}
          />
          <Message style={{ width: 284 }} body={messages[7]} />
        </div>
        <MessagePost
          boxShadow
          promiseId={EXAMPLE_POST_2}
          ref={messagesPostViolenceRef}
          style={{ width: 260 }}
          className={S.messagePost}
        />
        <SampleTileHighlight id={EXAMPLE_POST_2 - 1} ref={messageViolenceSampleRef} />
        <div ref={messagesObjectableRef} className={S.messages}>
          <Message style={{ width: 284 }} body={messages[8]} />
          <Message
            style={{
              width: 260,
              left: 18,
              marginBottom: 50,
              backgroundColor: '#485475',
              color: '#FFF',
              marginRight: 79,
              borderRadius: '8px 8px 8px 1px',
            }}
            body={messages[9]}
          />
          <Message style={{ width: 285 }} body={messages[10]} />
        </div>
        <SampleTileHighlight id={EXAMPLE_POST_3 - 1} ref={messageObjectableSampleRef} />

        <MessagePost
          boxShadow
          ref={messagesPostObjectableRef}
          promiseId={EXAMPLE_POST_3}
          style={{ width: 260 }}
          className={S.messagePost}
        />
        <div ref={messagesSafetyRef} className={S.messages}>
          <Message style={{ width: 230 }} body={messages[11]} />
          <Message
            style={{
              width: 260,
              left: 18,
              marginBottom: 50,
              backgroundColor: '#485475',
              color: '#FFF',
              marginRight: 79,
              borderRadius: '8px 8px 8px 1px',
            }}
            body={messages[12]}
          />
          <Message style={{ width: 220 }} body={messages[13]} />
        </div>
        <MessagePost
          boxShadow
          ref={messagesPostSafetyRef}
          promiseId={EXAMPLE_POST_4}
          style={{ width: 260 }}
          className={S.messagePost}
        />
        <div ref={messages36Ref} className={S.messages}>
          <Message style={{ width: 266 }} body={messages[14]} />
        </div>
        <MessagePost
          boxShadow
          ref={messagesPost36Ref}
          promiseId={EXAMPLE_POST_5}
          style={{ width: 260 }}
          className={S.messagePost}
        />
        <SampleTileHighlight id={EXAMPLE_POST_5 - 1} ref={message36SampleRef} />
        <div ref={messagesTwoOrMore} className={S.messages}>
          <Message
            style={{
              width: 232,
            }}
            body={messages[15]}
          />
        </div>
        <Legend ref={refLegend} />
        <div className={S.audios} ref={audiosRef}>
          <div className={S.audiosInner} ref={audiosInnerRef}>
            <Message
              style={{ width: 295 }}
              body={'By <strong>2018</strong>, we recorded <strong>162</strong> violations.'}
            />
            <AudioMessage
              ref={firstAudioRef}
              source={firstMp3}
              caption="Voice message from Mark"
              transcript={
                '“So this was a major breach of trust, and I’m really sorry that this happened”'
              }
              duration="0:05"
              year="2018"
            />
            <Message
              style={{ width: 299 }}
              body={'By <strong>2019</strong>, we recorded <strong>268</strong> violations.'}
            />
            <AudioMessage
              ref={secondAudioRef}
              source={secondMp3}
              caption="Voice message from Sheryl"
              transcript={'“We know we need to do better”'}
              duration="0:02"
              year="2019"
            />
            <Message
              style={{ width: 303 }}
              body={'By <strong>2020</strong>, we recorded <strong>394</strong> violations.'}
            />
            <AudioMessage
              ref={thirdAudioRef}
              source={thirdMp3}
              caption="Voice message from Mark"
              transcript={'“It was largely an operational mistake”'}
              duration="0:04"
              year="2020"
            />
            <Message
              style={{ width: 295 }}
              body={'By <strong>2021</strong>, we recorded <strong>415</strong> violations.'}
            />
            <AudioMessage
              ref={fourthAudioRef}
              source={fourthMp3}
              caption="Voice message from Mark"
              transcript={'“There will always be some mistakes”'}
              duration="0:02"
              year="2021"
            />
          </div>
        </div>
        {promiseSelected !== null && <SampleTileHighlight id={promiseSelected} />}
        {promiseSelected !== null && (
          <PromiseCard
            setPromise={() => {
              setPromise(null)
              promiseRef.current = false
            }}
            id={promiseSelected}
          />
        )}
      </div>
      {!hasClickedTile &&
        createPortal(
          <div className={S.overlay} ref={overlayRef}>
            <div
              className={S.overlayMessage}
              style={{
                top: Math.floor(POINTED_TILE / 30) * tileHeight - 26 - 56,
                left: 0 + (POINTED_TILE % 30) * tileWidth - 26 - 220,
              }}
            >
              Click on a tile to explore.
            </div>
            <Tile
              style={{
                width: tileWidth,
                height: tileHeight,
                position: 'absolute',
                top: Math.floor(POINTED_TILE / 30) * tileHeightNatural,
                left: 0 + (POINTED_TILE % 30) * tileWidth,
              }}
              colors={eventTypesToColors(data[POINTED_TILE].event_type)}
            />
            <Arrow
              style={{
                color: 'white',
                top: Math.floor(POINTED_TILE / 30) * tileHeight - 26,
                left: 0 + (POINTED_TILE % 30) * tileWidth - 26,
              }}
            />
          </div>,
          document.body
        )}
    </div>
  )
}
