/* eslint-disable react/jsx-max-props-per-line */
import { scaleLinear } from 'd3-scale'
import { chain, last, sortedUniqBy, toPairs, uniqBy, zip } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tile } from '../../components/Tile'
import initialData from '../../data/dataset.json'
import {
  useAnimatedStyle,
  useAnimatedStyleSelector,
  useAnimatedStyleVector,
} from '../../hooks/useAnimatedStyle'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import { useFrame } from '../../hooks/useFrame'
import {
  COLORS,
  eventTypesToColors,
  eventTypesToGrayScale,
  eventTypesToHateScale,
} from '../../utils'
import S from './S04_WallStretch.module.css'
import messages from '../../data/messages_2.json'
import { Message } from '../../components/Message'
import { useAnimatedProps } from '../../hooks/useAnimatedProps'

const responseTypesCount = {}

initialData
  .filter((d) => d.facebook_response_type !== 'no response')
  .map((d) => d.facebook_response_type)
  .forEach((frt) => {
    if (responseTypesCount[frt]) {
      responseTypesCount[frt] = responseTypesCount[frt] + 1
    } else {
      responseTypesCount[frt] = 1
    }
  })

const sortedResponseTypes = chain(responseTypesCount)
  .toPairs()
  .sortBy('1')
  .map('0')
  .map((item, idx) => [item, idx])
  .fromPairs()
  .value()

const finalOrdering = chain(initialData)
  .map((datum, i) => {
    return { ...datum, pk: i }
  })
  .filter((d) => d.facebook_response_type !== 'no response')
  .sort((a, b) => {
    const at = sortedResponseTypes[a.facebook_response_type]
    const bt = sortedResponseTypes[b.facebook_response_type]
    return at === bt ? a.pk - b.pk : at - bt
  })
  .map((item, i) => [item.pk, i])
  .fromPairs()
  .value()

const finalUnsorted = chain(initialData)
  .map((datum, i) => {
    return { ...datum, pk: i }
  })
  .filter((d) => d.facebook_response_type === 'no response')
  .map((item, i) => [item.pk, i])
  .fromPairs()
  .value()

const responded = Object.keys(finalOrdering).length
const notResponded = initialData.length - responded

const data = initialData.map((datum, i) => {
  return {
    ...datum,
    priority: Math.round(Math.random() * 1000),
    pk: i,
    sortIdx: finalOrdering[i] ?? finalUnsorted[i],
  }
})

const dataByResponseType = {}
for (const datum of initialData) {
  if (
    datum.facebook_response_type !== 'no response' &&
    datum.facebook_response_type !== 'denied and enforced later'
  ) {
    const res = datum.facebook_response_type
    if (res == 'changed policy' || res == 'removed content') {
      //unisce due facebook_response_type in un unico array
      if (dataByResponseType['changed policy & removed content']) {
        dataByResponseType['changed policy & removed content'].push(datum)
      } else {
        dataByResponseType['changed policy & removed content'] = [datum]
      }
    } else if (dataByResponseType[res]) {
      dataByResponseType[res].push(datum)
    } else {
      dataByResponseType[res] = [datum]
    }
  }
}

const ROWS = Math.ceil(responded / 30) + Math.ceil(notResponded / 30)
const ROWS_INITIAL = Math.ceil(data.length / 30)

const responsesSorted = Object.keys(dataByResponseType).sort(
  (a, b) => dataByResponseType[a].length - dataByResponseType[b].length
)

function getHorizontalPosition(width, index) {
  return ((index % 30) * width) / 30
}

function getVerticalPosition(height, index, rows) {
  return (height / rows) * Math.floor(index / 30)
}

function getHorizontalPositionStretched(width, index) {
  return ((index % 30) * width) / 30
}

function getVerticalPositionStretched(height, index) {
  return (height / Math.ceil(responded / 30)) * Math.floor(index / 30)
}

function tween(color1, color2, min, current, max) {
  return scaleLinear().domain([min, max]).range([color1, color2]).clamp(true)(current)
}

const COLS_INIT_SECOND_ANIM = dataByResponseType[responsesSorted[0]].length

export function WallStretch({ scrollId, progress, animation }) {
  const animCtx = useAnimationContext({ numTiles: data.length })
  const pageRef = useRef()
  const tilesRef = useRef()
  const countSwatchStyleRef = useRef()
  const countSwatchRef = useRef()
  const responsesRef = useRef()
  const message00Ref = useRef()
  const message01Ref = useRef()
  const message02Ref = useRef()
  const message03Ref = useRef()
  const message04Ref = useRef()
  const message05Ref = useRef()

  const { width, height } = useFrame()

  const tilesAnimator = useMemo(() => {
    return animation.animations.tile(progress, animCtx)
  }, [progress, animCtx])
  const respAnimator = useMemo(() => {
    return animation.animations.recolor(progress, animCtx)
  }, [progress, animCtx])

  useAnimatedStyleVector(tilesRef, (...args) => {
    const aaa = tilesAnimator(...args)
    if (!aaa) {
      return null
    }
    const { step } = aaa
    const { index } = args[0]
    const datum = data[index]
    const backgroundOriginal = eventTypesToColors(datum.event_type)
    const backgroundGray = eventTypesToGrayScale(datum.event_type)
    const backgroundTarget =
      datum.facebook_response_type === 'no response' ? backgroundGray : backgroundOriginal
    const backgroundCurrent = zip(backgroundOriginal, backgroundTarget).map(([color1, color2]) =>
      tween(color1, color2, datum.priority, step, datum.priority + 500)
    )
    let gradient =
      backgroundCurrent.length === 1
        ? backgroundCurrent
        : `linear-gradient(${backgroundCurrent.join(', ')})`
    const tileHeightInitial = height / ROWS_INITIAL
    const tileHeightSorted = height / ROWS
    const tileHeightStretched = height / Math.ceil(responded / 30)
    const tileWidthInitial = width / 30
    const tileWidthStretched = width / 30
    const leftInitial = getHorizontalPosition(window.innerWidth, index)
    const topInitial = getVerticalPosition(window.innerHeight - 80, index, ROWS_INITIAL)
    const leftSorted = getHorizontalPosition(window.innerWidth, datum.sortIdx)
    const topSorted =
      getVerticalPosition(window.innerHeight - 80, datum.sortIdx, ROWS) +
      (datum.facebook_response_type === 'no response'
        ? Math.ceil(responded / 30) * tileHeightSorted
        : 0)
    const leftStretched = getHorizontalPositionStretched(width, datum.sortIdx)
    const topStretched = getVerticalPositionStretched(height, datum.sortIdx)

    const leftScale = scaleLinear()
      .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
      .range([
        leftInitial,
        leftSorted,
        leftSorted,
        leftStretched,
        leftStretched,
        leftStretched,
        leftInitial,
      ])
      .clamp(true)
    const topScale = scaleLinear()
      .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
      .range([
        topInitial,
        topSorted,
        topSorted,
        topStretched,
        topStretched,
        topStretched,
        topInitial,
      ])
      .clamp(true)
    let heightScale, widthScale
    if (datum.sortIdx > 0) {
      heightScale = scaleLinear()
        .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
        .range([
          tileHeightInitial,
          tileHeightSorted,
          tileHeightSorted,
          tileHeightStretched,
          tileHeightStretched,
          tileHeightStretched,
          tileHeightInitial,
        ])
        .clamp(true)
      widthScale = scaleLinear()
        .domain([3100, 4000, 5000, 6000, 6500])
        .range([
          tileWidthInitial,
          tileWidthStretched,
          tileWidthStretched,
          tileWidthStretched,
          tileWidthInitial,
        ])
        .clamp(true)
    } else {
      heightScale = scaleLinear()
        .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
        .range([
          tileHeightInitial,
          tileHeightSorted,
          tileHeightSorted,
          tileHeightStretched,
          tileHeightStretched,
          height,
          tileHeightInitial,
        ])
        .clamp(true)
      widthScale = scaleLinear()
        .domain([3100, 4000, 5000, 6000, 6500])
        .range([tileWidthInitial, tileWidthStretched, tileWidthStretched, width, tileWidthInitial])
        .clamp(true)
    }

    return {
      background: gradient,
      opacity:
        datum.facebook_response_type === 'no response'
          ? scaleLinear().domain([3000, 3100]).range([1, 0]).clamp(true)(step)
          : 1,
      left: leftScale(step) + 'px',
      top: topScale(step) + 'px',
      height: heightScale(step) + 'px',
      width: widthScale(step) + 'px',
      'z-index': datum.sortIdx === 0 && step >= 5000 && step <= 6000 ? 1 : 0,
    }
  })

  useAnimatedStyle(responsesRef, animation.animations.responses(progress, animCtx))

  useAnimatedStyleSelector(responsesRef, '.tile', (...args) => {
    const aaa = respAnimator(...args)
    if (!aaa) {
      return null
    }
    const { step } = aaa
    const { node } = args[0]
    const response = node.dataset.response
    const index = parseInt(node.dataset.index, 10)
    const datum = dataByResponseType[response][index]
    const initialColors = eventTypesToColors(datum.event_type)
    const finalColors = eventTypesToHateScale(datum.event_type)
    const grayColors = eventTypesToGrayScale(datum.event_type)
    let currentColors = null
    if (step <= (responsesSorted.length - 1) * 1000) {
      const rowsGreyedOut = Math.floor(step / 1000)
      const respsGrayedOut = responsesSorted.slice(0, rowsGreyedOut)
      if (respsGrayedOut.includes(response)) {
        currentColors = grayColors
      } else {
        const rowGreyingOut = rowsGreyedOut
        if (response === responsesSorted[rowGreyingOut]) {
          currentColors = zip(initialColors, grayColors).map(([color1, color2]) =>
            tween(color1, color2, rowGreyingOut * 1000, step, (rowGreyingOut + 1) * 1000)
          )
        } else {
          currentColors = initialColors
        }
      }
    } else if (step <= responsesSorted.length * 1000) {
      if (response !== last(responsesSorted)) {
        currentColors = zip(grayColors, initialColors).map(([color1, color2]) =>
          tween(
            color1,
            color2,
            (responsesSorted.length - 1) * 1000,
            step,
            responsesSorted.length * 1000
          )
        )
      } else {
        currentColors = initialColors
      }
    } else {
      currentColors = zip(initialColors, finalColors).map(([color1, color2]) =>
        tween(
          color1,
          color2,
          responsesSorted.length * 1000,
          step,
          (responsesSorted.length + 1) * 1000
        )
      )
    }
    if (currentColors.length === 1) {
      return {
        background: currentColors[0],
      }
    } else {
      return {
        background: `linear-gradient(${currentColors.join(', ')})`,
      }
    }
  })

  useAnimatedStyle(message00Ref, animation.animations.message00(progress, animCtx))
  useAnimatedStyle(countSwatchStyleRef, animation.animations.count_style(progress, animCtx))
  const tileNumberAnimator = useMemo(() => {
    return animation.animations.count_text(progress, animCtx)
  }, [progress, animCtx])
  useAnimatedProps(countSwatchRef, (...args) => {
    const aaa = tileNumberAnimator(...args)
    if (!aaa) {
      return null
    }
    const { textContent } = aaa
    return { textContent: Math.floor(textContent).toString(10) }
  })

  useAnimatedStyle(message01Ref, animation.animations.message01(progress, animCtx))
  useAnimatedStyle(message02Ref, animation.animations.message02(progress, animCtx))
  useAnimatedStyle(message03Ref, animation.animations.message03(progress, animCtx))
  useAnimatedStyle(message04Ref, animation.animations.message04(progress, animCtx))
  useAnimatedStyle(message05Ref, animation.animations.message05(progress, animCtx))

  return (
    <div
      style={{ width: '100vw', position: 'relative' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      ref={pageRef}
    >
      <div className="menu-placeholder-desktop" />
      <div className={S.wall}>
        <div className={S.countSwatch} ref={countSwatchStyleRef}>
          <div className={S.numberTilesLabel}>violations</div>
          <div className={S.numberTiles} ref={countSwatchRef}></div>
        </div>

        <div ref={tilesRef}>
          {data.map((datum, i) => {
            return (
              <Tile
                key={i}
                style={{
                  position: 'absolute',
                }}
                colors={eventTypesToColors(datum.event_type)}
              />
            )
          })}
        </div>
      </div>
      <div className={S.responses} ref={responsesRef}>
        {responsesSorted.map((resp) => (
          <div key={resp} className={S.responsesRow}>
            {dataByResponseType[resp].map((datum, i) => (
              <Tile
                key={i}
                style={{
                  width: width / dataByResponseType[resp].length,
                }}
                data-response={resp}
                data-index={i}
                className="tile"
                colors={eventTypesToColors(datum.event_type)}
              />
            ))}
          </div>
        ))}
      </div>
      <Message
        style={{
          width: 350,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          borderRadius: 8,
          zIndex: 5,
        }}
        body={messages[0]}
        boxShadow
        ref={message00Ref}
      />
      <Message
        style={{
          width: 320,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          borderRadius: 8,
          zIndex: 5,
        }}
        body={messages[1]}
        boxShadow
        ref={message01Ref}
      />
      <Message
        style={{
          width: 260,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          borderRadius: 8,
          zIndex: 5,
        }}
        body={messages[2]}
        boxShadow
        ref={message02Ref}
      />
      <Message
        style={{
          width: 240,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          borderRadius: 8,
          zIndex: 5,
        }}
        body={messages[3]}
        boxShadow
        ref={message03Ref}
      />
      <Message
        style={{
          width: 280,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          borderRadius: 8,
          zIndex: 5,
        }}
        body={messages[4]}
        boxShadow
        ref={message04Ref}
      />
      <Message
        style={{
          width: 400,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          borderRadius: 8,
          zIndex: 5,
        }}
        body={messages[5]}
        boxShadow
        ref={message05Ref}
      />
    </div>
  )
}
