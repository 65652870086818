import React, { useCallback, useEffect, useMemo, useRef, useState, useContext, createContext } from 'react'
import { scaleLinear } from 'd3-scale'
import { debounce, last, map } from 'lodash'

export const ScrollerTools = React.createContext({})

const TRAP_TIMEOUT = 200
const MULT = 1
const Context = createContext()

function ScrollerImpl({ children, externalHeight = 0, animations, scrollHintRef }) {
  console.log(scrollHintRef)
  const domRef = useRef()
  const support = useRef([])
  const pageScrollFn = useRef(() => 0)
  const jumpPoints = useRef({})
  const previousScroll = useRef(0)
  const traps = useRef({
    list: [],
    timeout: null,
    active: 0,
    isTrending: false,
    enabled: true,
    isLockingFor: 0,
    wheelUnlock: true,
  })
  const [force, setForce] = useState(0)

  const isMobile = window.matchMedia('(max-width: 768px)').matches

  const jumpTo = useCallback((name) => {
    if (jumpPoints.current[name] !== undefined) {
      traps.current.enabled = false
      window.scrollTo(0, jumpPoints.current[name])
      window.setTimeout(() => {
        traps.current.enabled = true
      }, 10)
    }
  }, [])

  const triggerRecomputation = useCallback(() => {
    setTimeout(() => {
      setForce((f) => f + 1)
    }, 20)
  }, [])

  const scrollerCtx = useMemo(() => {
    return {
      jumpTo,
      jumpPoints,
      triggerRecomputation,
    }
  }, [jumpTo])

  const renderChildren = useMemo(() => {
    const supportArray = []
    const result = React.Children.map(children, (child, i) => {
      supportArray.push({
        value: 0,
        exiting: 0,
        entering: 0,
        updater: () => 0,
        activeCheck: () => 0,
        exitingFn: () => 0,
        enteringFn: () => 0,
        componentTop: 0,
        componentBottom: 0,
        global: 0,
        neat: 0,
      })
      return React.cloneElement(child, {
        scrollId: `IK${i}`,
        progress: supportArray[i],
        _progressFull: supportArray,
        jumpTo,
        jumpPoints,
        triggerRecomputation,
      })
    })
    support.current = supportArray
    return result
  }, [jumpTo]) // children is missing here but, really, no, don't put it here, it is not necessary and it breaks things
  // this is because the progress state, which is kept in a ref for perf reasons, is re-initialized

  useEffect(() => {
    document.fonts.ready.then(() => {
      let cumulatedHeight = 0
      const pageScaleData = [[0, 0]]
      let i = 0
      traps.current.list = []
      // eslint-disable-next-line no-unused-vars
      for (const child of React.Children.toArray(children)) {
        const node = document.querySelector(`[data-scroll-id="IK${i}"]`)
        const height = node.offsetHeight
        const animationKey = node.dataset.scrollAnimationKey
        const animationObj = animations.find((anim) => anim.key === animationKey)
        const extraHeight = animationObj.duration
        const componentBottom = cumulatedHeight + extraHeight + height
        const componentTop = cumulatedHeight
        if (node.dataset.jumpName) {
          jumpPoints.current[node.dataset.jumpName] = Math.floor(componentTop)
          if (node.dataset.jumpDisplacement) {
            jumpPoints.current[node.dataset.jumpName] += parseInt(node.dataset.jumpDisplacement)
          }
        }
        // console.log(`Node for IK${i} is `, node, height, extraHeight)
        support.current[i].updater = scaleLinear()
          .domain([0, cumulatedHeight, cumulatedHeight + extraHeight, Infinity])
          .range([0, 0, extraHeight, extraHeight])
        support.current[i].activeCheck = scaleLinear()
          .domain([
            0,
            cumulatedHeight,
            cumulatedHeight,
            cumulatedHeight + extraHeight,
            cumulatedHeight + extraHeight,
            Infinity,
          ])
          .range([0, 0, 1, 1, 0, 0])
        support.current[i].exitingFn = (arg) => {
          const windowHeight = window.innerHeight
          const scale = scaleLinear()
            .domain([0, Math.max(0, componentBottom - windowHeight), componentBottom, Infinity])
            .range([0, 0, 1, 1])
          return scale(arg)
        }
        support.current[i].componentTop = componentTop
        support.current[i].componentBottom = componentBottom
        support.current[i].enteringFn = (arg) => {
          const windowHeight = window.innerHeight
          const scale = scaleLinear()
            .domain([0, Math.max(0, componentTop - windowHeight), componentTop, Infinity])
            .range([0, 0, 1, 1])
          return scale(arg)
        }
        traps.current.list.push(
          ...animationObj.traps.map((trap) => ({
            ...trap,
            position: trap.position + cumulatedHeight,
            lockFor: trap.lockFor ?? TRAP_TIMEOUT,
          }))
        )
        i += 1
        if (extraHeight) {
          pageScaleData.push([last(pageScaleData)[0] + extraHeight, last(pageScaleData)[1]])
        }
        pageScaleData.push([last(pageScaleData)[0] + height, last(pageScaleData)[1] + height])
        cumulatedHeight += height + extraHeight
      }
      pageScrollFn.current = scaleLinear()
        .domain(map(pageScaleData, 0))
        .range(map(pageScaleData, 1))
      document.body.style.height = `${(cumulatedHeight + externalHeight) * MULT}px`
      if (window.mckRestoreScroll) {
        traps.current.enabled = false
        if (jumpPoints.current[window.mckRestoreScroll] !== undefined) {
          window.scrollTo(0, jumpPoints.current[window.mckRestoreScroll])
        } else {
          window.scrollTo(0, window.mckRestoreScroll)
        }
        window.setTimeout(() => {
          traps.current.enabled = true
        }, 10)
        window.animatorShouldForceRender = true
        delete window.mckRestoreScroll
      }
    })
  }, [children, force, animations])

  useEffect(
    () => () => {
      document.body.style.height = 'auto'
    },
    []
  )

  useEffect(() => {
    let lastTs = -Infinity
    const handler = (e) => {
      if (e.timeStamp < lastTs) {
        return
      } else {
        lastTs = e.timeStamp
      }
      let y = document.documentElement.scrollTop / MULT
      const oldy = previousScroll.current
      const delta = y - oldy
      if (delta === 0) {
        return
      }
      const doTrap = traps.current.enabled
      const nextTrapIndex =
        delta > 0
          ? traps.current.list.filter((t) => t.position <= oldy).length
          : traps.current.list.filter((t) => t.position < oldy).length - 1
      const nextTrap = traps.current.list[nextTrapIndex]?.position
      const nextTrapLockFor = traps.current.list[nextTrapIndex]?.lockFor
      const nextTrapAllowsUnlock = !!traps.current.list[nextTrapIndex]?.allowUnlock
      if (traps.current.timeout !== null) {
        clearTimeout(traps.current.timeout)
        if (doTrap) {
          traps.current.timeout = setTimeout(() => {
            traps.current.timeout = null
            window.scrollTo(0, traps.current.active * MULT)
          }, traps.current.isLockingFor)
          window.scrollTo(0, traps.current.active * MULT)
          return
        }
      }
      if (doTrap && ((delta > 0 && y >= nextTrap) || (delta < 0 && y <= nextTrap))) {
        traps.current.active = nextTrap
        traps.current.isLockingFor = nextTrapLockFor
        traps.current.wheelUnlock = nextTrapAllowsUnlock
        y = nextTrap
        traps.current.timeout = setTimeout(() => {
          traps.current.timeout = null
          window.scrollTo(0, traps.current.active * MULT)
        }, traps.current.isLockingFor)
      }
      const translationY = pageScrollFn.current(y)
      for (let i = 0; i < support.current.length; i++) {
        support.current[i].value = support.current[i].updater(y)
        support.current[i].isActive = support.current[i].activeCheck(y)
        support.current[i].exiting = support.current[i].exitingFn(y)
        support.current[i].entering = support.current[i].enteringFn(y)
        support.current[i].past = y > support.current[i].componentBottom
        support.current[i].current =
          y >= support.current[i].componentTop && y <= support.current[i].componentBottom
        support.current[i].future = y < support.current[i].componentTop
        support.current[i].global = translationY
        support.current[i].neat =
          y === 0 ? 0 : Math.max(0, Math.min(isMobile ? 56 : 70, support.current[i].neat + delta))
      }
      if (domRef.current) {
        domRef.current.firstChild.style.transform = `translateY(${-translationY}px)`
        scrollHintRef.current.style.transform = `translate(-50% , ${-translationY - scrollHintRef.current.offsetHeight}px)`
      }
      previousScroll.current = y
    }

    const wheelHandler = debounce(
      () => {
        if (traps.current.timeout !== null && traps.current.wheelUnlock) {
          clearTimeout(traps.current.timeout)
          traps.current.timeout = null
        }
      },
      30,
      { leading: true, trailing: false }
    )

    window.addEventListener('scroll', handler, { passive: false })
    window.addEventListener('wheel', wheelHandler)
    window.addEventListener('touchstart', wheelHandler)
    window.mckScrollToIndex = (i) => {
      window.scrollTo(0, support.current[i].componentTop)
    }

    return () => {
      window.removeEventListener('scroll', handler, { passive: false })
      window.removeEventListener('wheel', wheelHandler)
      window.removeEventListener('touchstart', wheelHandler)
      delete document.body.style.height
    }
  }, [])

  return (
    <ScrollerTools.Provider value={scrollerCtx}>
      <div
        ref={domRef}
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <div>{renderChildren}</div>
      </div>
    </ScrollerTools.Provider>
  )
}

export function persistScroll() {
  window.mckRestoreScroll = document.documentElement.scrollTop
}

export function scrollerReturnTo(waypoint) {
  window.mckRestoreScroll = waypoint
}

export const Scroller = React.memo(ScrollerImpl)
