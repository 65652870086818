import React, { useEffect, useState } from 'react'
import S from './S05_Repository.module.css'
import statements from '../../data/statement.json'
import dataset from '../../data/dataset.json'
import { find, groupBy } from 'lodash'
import { eventTypesToColors, eventTypeToColor, getBackground } from '../../utils'
import { Tile } from '../../components/Tile'
import closeIcon from '../../assets/close-detail.svg'
import { useFrame } from '../../hooks/useFrame'

const FIRST_ID_EXAMPLE = 30
const SECOND_ID_EXAMPLE = 256

export default function Repository({ scrollId, progress, animation, triggerRecomputation }) {
  const [showMoreCitation, setShowMore] = useState(false)
  const [showMoreCitationSecond, setShowMoreSecond] = useState(false)
  const { width, height } = useFrame()

  const widthStatementFree = width / 4 - 15 * 5

  const statementsToUse = statements.map((stat) => ({
    year: new Date(find(dataset, { ID: stat.ID }).date).getFullYear(),
    ...find(dataset, { ID: stat.ID }),
    ...stat,
  }))
  const groupedStatement = groupBy(statementsToUse, 'Statement')
  const firstExample = find(dataset, { ID: FIRST_ID_EXAMPLE })
  const secondExample = find(dataset, { ID: SECOND_ID_EXAMPLE })

  useEffect(() => {
    triggerRecomputation()
  }, [showMoreCitation, showMoreCitationSecond])

  return (
    <div
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      className={S.Repository}
    >
      <div className={S.StatementLabel}>
        When responding to reports, Facebook often drew on cookie-cutter PR statements:
      </div>
      <div className={S.ExamplesPromises}>
        <div
          className={S.Promise}
          style={{
            background: getBackground(firstExample.event_type),
            width: showMoreCitation ? 634 : showMoreCitationSecond ? 0 : 400,
            display: showMoreCitationSecond ? 'none' : 'block',
          }}
        >
          <div className={S.PromiseBody}>
            <div className={S.BlockLeftRotate}>
              {showMoreCitation && (
                <div
                  onClick={() => {
                    setShowMore(null)
                  }}
                  className={S.CloseCard}
                >
                  <img src={closeIcon} />
                </div>
              )}
              <div className={S.NumberBlock}>
                <div className={S.DatePromise}>{firstExample.date}</div>
                <div className={S.BorderLine}></div>
                <div className={S.NumberPromise}>VIOLATION #{firstExample.ID}</div>
              </div>
            </div>
            <div>
              <div className={S.TitlePromise}>{firstExample.title}</div>
              <div className={S.Topic}>
                <div className={S.labelTopic}>Topic</div>
                <div className={S.TopicName}>{firstExample.topic}</div>
              </div>
              <div className={S.Event}>
                <div className={S.labelTopic}>Event type</div>
                {firstExample.event_type.split(',').map((event, i) => (
                  <div className={S.EventContainer} key={i}>
                    <div
                      className={S.CircleEvent}
                      style={{ background: eventTypeToColor(event.trim()) }}
                    ></div>
                    <div className={S.NameEvent}>{event}</div>
                  </div>
                ))}
              </div>
              <div className={S.BlockBlack}>
                <div className={S.Citation} onClick={() => setShowMore(!showMoreCitation)}>
                  {!showMoreCitation ? (
                    <>
                      <span>
                        “
                        <span className={S.CitationStrong}>
                          We want everyone using Facebook to feel safe
                        </span>
                        ...”
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        “
                        <span className={S.CitationStrong}>
                          We want everyone using Facebook to feel safe
                        </span>{' '}
                        that's why we have Community Standards and remove anything that violates
                        them, including violent content.”
                      </span>
                      <br />
                      <br />
                      <div className={S.CitationAuthor}>-A Facebook Spokesperson</div>
                    </>
                  )}
                </div>
                {!showMoreCitation && (
                  <div onClick={() => setShowMore(true)} className={S.ContinueReading}>
                    Read full statement
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={S.Promise}
          style={{
            background: getBackground(secondExample.event_type),
            width: showMoreCitationSecond ? 750 : showMoreCitation ? 0 : 400,
            display: showMoreCitation ? 'none' : 'block',
          }}
        >
          <div className={S.PromiseBody}>
            <div className={S.BlockLeftRotate}>
              {showMoreCitationSecond && (
                <div
                  onClick={() => {
                    setShowMoreSecond(null)
                  }}
                  className={S.CloseCard}
                >
                  <img src={closeIcon} />
                </div>
              )}
              <div className={S.NumberBlock}>
                <div className={S.DatePromise}>{secondExample.date}</div>
                <div className={S.BorderLine}></div>
                <div className={S.NumberPromise}>VIOLATION #{secondExample.ID}</div>
              </div>
            </div>
            <div>
              <div className={S.TitlePromise}>{secondExample.title}</div>
              <div className={S.Topic}>
                <div className={S.labelTopic}>Topic</div>
                <div className={S.TopicName}>{secondExample.topic}</div>
              </div>
              <div className={S.Event}>
                <div className={S.labelTopic}>Event type</div>
                {secondExample.event_type.split(',').map((event, i) => (
                  <div className={S.EventContainer} key={i}>
                    <div
                      className={S.CircleEvent}
                      style={{ background: eventTypeToColor(event.trim()) }}
                    ></div>
                    <div className={S.NameEvent}>{event}</div>
                  </div>
                ))}
              </div>
              <div className={S.BlockBlack}>
                <div
                  className={S.Citation}
                  onClick={() => setShowMoreSecond(!showMoreCitationSecond)}
                >
                  {!showMoreCitationSecond ? (
                    <>
                      <span>
                        “
                        <span className={S.CitationStrong}>
                          We want members of our community to feel safe and respected on Facebook
                        </span>
                        ...”
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        “
                        <span className={S.CitationStrong}>
                          We want members of our community to feel safe and respected on Facebook
                        </span>{' '}
                        and will remove material that appears to purposefully target private
                        individuals with the intention of degrading or shaming them. We try to
                        empower our users with controls, such as blocking other users and moderating
                        comments, so they can limit their exposure to unwanted, offensive or hurtful
                        content. We also encourage people to report bullying behaviour on our
                        platform, so we can review the content and take proper action.”
                      </span>
                      <div className={S.CitationAuthor}>-A Facebook Spokesperson</div>
                    </>
                  )}
                </div>
                {!showMoreCitationSecond && (
                  <div onClick={() => setShowMoreSecond(true)} className={S.ContinueReading}>
                    Read full statement
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={S.StatementLabel} style={{ marginTop: '100px', marginBottom: '60px' }}>
        <p>We analyzed Facebook’s responses to identify the patterns.</p>
        <p>Facebook used versions of these four phrases repeatedly:</p>
      </div>
      <div className={S.StatementsFlex}>
        {Object.keys(groupedStatement).map((statementInitial) => (
          <div key={statementInitial} className={S.SlidePromise}>
            <div className={S.TitleStatement}>{statementInitial}</div>
            <div className={S.NumberOccurence}>
              {groupedStatement[statementInitial].length} occurrences of this sentence
            </div>
            <div className={S.StatementsList}>
              {Object.keys(groupBy(groupedStatement[statementInitial], 'year')).map((year) => (
                <div key={year} className={S.BlockYear}>
                  <div className={S.TilesListStatement}>
                    {groupBy(groupedStatement[statementInitial], 'year')[year].map((statement) => (
                      <Tile
                        style={{
                          height: 80,
                          marginRight: 1.5,
                          width:
                            (widthStatementFree -
                              1.5 * groupedStatement[statementInitial].length -
                              30) /
                            groupedStatement[statementInitial].length,
                        }}
                        key={statement.ID}
                        colors={eventTypesToColors(statement.event_type)}
                      />
                    ))}
                  </div>
                  <div className={S.YearStatement}>{year}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={S.FinalSlogan}>
        <p className={S.StatementLabel}>
          Facebook has systematically failed to police its platform despite repeated promises by its
          executives.
        </p>
        <p className={S.StatementLabel}>
          It routinely permits violations of its policies on violence, hate speech and other topics.
        </p>
        <p className={S.StatementLabel}>
          When called out by the media, it rarely acts to fix the problem, resorting instead to
          recycled PR statements.
        </p>
        <p className={S.StatementLabel} style={{ fontWeight: 520 }}>
          The time for talking is over. With Facebook unable or unwilling to clean up its platform,
          it’s time for regulators to step in.
        </p>
        <p className={S.StatementLabel}>■</p>
      </div>
    </div>
  )
}
