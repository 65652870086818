import React from 'react'
import { useFrame } from '../hooks/useFrame'

function _Tile({ style, colors, number = undefined, ...props }, ref) {
  const { isMobile } = useFrame()
  if (typeof colors === 'string') {
    return (
      <div {...props} ref={ref} style={{ ...style, background: colors }}>
        {number !== undefined && number}
      </div>
    )
  }
  if (Array.isArray(colors) && colors.length === 1) {
    return <div {...props} ref={ref} style={{ ...style, background: colors[0] }}></div>
  }
  return (
    <div
      {...props}
      ref={ref}
      style={{
        ...style,
        background: `linear-gradient(${colors.join(', ')})`,
        cursor: isMobile ? 'unset' : 'pointer',
      }}
    ></div>
  )
}

export const Tile = React.forwardRef(_Tile)
