import { flatMap, keyBy, uniqBy } from 'lodash'
import dataset from '../../data/dataset.json'

const data = dataset.map((item) => ({ ...item, year: parseInt(item.date.split('/')[2], 10) }))
const years = Object.keys(keyBy(data, 'year'))

export default {
  key: 'S03',
  sequence: [
    {
      type: 'interpolate',
      key: 'quote',
      duration: 10,
      from: {
        transform: `translateY(0px)`,
      },
      to: {
        transform: `translateY(0px)`,
      },
    },
  ],
}
