import { scaleLinear } from 'd3-scale'
import { isEmpty, isEqual, last, mapValues, xor } from 'lodash'

export function compileAnimation(animationDescription, frame) {
  const traps = []
  let duration = 0
  const interpolations = {}

  for (const item of animationDescription.sequence) {
    const itemDuration = typeof item.duration === "function" ? item.duration(frame) : item.duration
    if(item.type === 'volume') {
      console.log(item)
      
    }
    if (item.type === 'still') {
      duration = duration + itemDuration
    }
    if (item.type === 'interpolate') {
      if (interpolations[item.key]) {
        const interp = interpolations[item.key]
        const lastState = last(interp.steps).state
        const propsDiff = xor(Object.keys(item.to), interp.props)
        if (isEmpty(propsDiff)) {
          interpolations[item.key].steps.push(
            { at: duration, state: lastState },
            { at: duration + itemDuration, state: item.to }
          )
          interpolations[item.key].endsAt = duration + itemDuration
        } else {
          throw new Error(
            `Please define props ${propsDiff.join(', ')} in step ${item.key} in animation ${
              animationDescription.key
            }`
          )
        }
      } else {
        interpolations[item.key] = {
          props: Object.keys(item.from),
          steps: [
            { at: 0, state: item.from },
            { at: duration, state: item.from },
            { at: duration + itemDuration, state: item.to },
          ],
          startsAt: duration,
          endsAt: duration + itemDuration,
          logs: !!item.logs,
        }
      }
      if (!item.detatch) {
        duration = duration + itemDuration
      }
    }
    if (item.type === 'trap') {
      traps.push({
        position: duration,
        lockFor: item.lockFor,
        allowUnlock: item.allowUnlock ?? true,
      })
    }
    if (item.type === 'sequence') {
      if (!item.detatch === true) {
        throw new Error('Sequences MUST be detatched')
      }
      let localDuration = duration
      for (const child of item.children) {
        const childDuration = typeof child.duration === "function" ? child.duration(frame) : child.duration
        if (child.type === 'still') {
          localDuration = localDuration + childDuration
        }
        if (child.type === 'interpolate') {
          if (interpolations[child.key]) {
            const interp = interpolations[child.key]
            const lastState = last(interp.steps).state
            const propsDiff = xor(Object.keys(child.to), interp.props)
            if (isEmpty(propsDiff)) {
              interpolations[child.key].steps.push(
                { at: localDuration, state: lastState },
                { at: localDuration + childDuration, state: child.to }
              )
              interpolations[child.key].endsAt = localDuration + childDuration
            } else {
              throw new Error(
                `Please define props ${propsDiff.join(', ')} in step ${child.key} in animation ${
                  animationDescription.key
                }`
              )
            }
          } else {
            if (!child.from) {
              throw new Error(
                `Please define initial state for sequence ${child.key} in animation ${animationDescription.key}`
              )
            }
            interpolations[child.key] = {
              props: Object.keys(child.from),
              steps: [
                { at: 0, state: child.from },
                { at: localDuration, state: child.from },
                { at: localDuration + childDuration, state: child.to },
              ],
              startsAt: localDuration,
              endsAt: localDuration + childDuration,
              logs: !!child.logs,
            }
          }
          localDuration = localDuration + childDuration
        }
        if (child.type === 'trap') {
          traps.push({
            position: localDuration,
            lockFor: item.lockFor,
            allowUnlock: item.allowUnlock ?? true,
          })
        }
      }
    }
  }
  const animations = {}
  for (let key in interpolations) {
    const interpolation = interpolations[key]
    const timeDomain = interpolation.steps.map((s) => s.at)
    let mems = {
      shouldRunExtraLoop: true,
      memoCtx: null,
      isBefore: true,
    }
    animations[key] = function (progress, ctx) {
      const scales = {}
      for (const prop of interpolation.props) {
        const propRange = interpolation.steps.map((s) => s.state[prop])
        if (propRange.some((rangeStep) => typeof rangeStep === 'function')) {
          const baseScale = scaleLinear().domain(timeDomain).clamp(true)
          scales[prop] = (extraArg) => {
            return baseScale.range(
              propRange.map((rangeStep) =>
                typeof rangeStep !== 'function' ? rangeStep : rangeStep(ctx.current, extraArg)
              )
            )
          }
          scales[prop].__is_indirect = true
        } else {
          scales[prop] = scaleLinear().domain(timeDomain).range(propRange).clamp(true)
        }
      }
      return function (extraArg) {
        if (!isEqual(mems.memoCtx, ctx.current)) {
          mems.shouldRunExtraLoop = true
          mems.memoCtx = ctx.current
        }
        const isBefore = progress.value <= interpolation.startsAt,
          isAfter = progress.value > interpolation.endsAt,
          isHidden = isBefore || isAfter
        if (isHidden && mems.isBefore !== isBefore) {
          mems.shouldRunExtraLoop = true
        }
        if (window.animatorShouldForceRender) {
          mems.shouldRunExtraLoop = true
        }
        mems.isBefore = isBefore
        if (isHidden && !mems.shouldRunExtraLoop) {
          return null
        } else if (isHidden && mems.shouldRunExtraLoop) {
          const isIterative = extraArg?.last === true || extraArg?.last === false
          const isIterativeLast = extraArg?.last === true
          mems.shouldRunExtraLoop = isIterative ? !isIterativeLast : false
        } else if (!isHidden) {
          mems.shouldRunExtraLoop = true
        }
        return mapValues(scales, (scale) => {
          if (scale.__is_indirect) {
            return scale(extraArg)(progress.value)
          } else {
            return scale(progress.value)
          }
        })
      }
    }
  }
  return {
    key: animationDescription.key,
    animations,
    traps,
    duration,
  }
}
