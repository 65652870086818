import React, { useMemo, useState } from 'react'
import S from './PromiseCard.module.css'
import dataset from '../data/dataset.json'
import { eventTypeToColor, getBackground } from '../utils'
import closeIcon from '../assets/close-detail.svg'
import iconSensitive from '../assets/icon-sensitive.svg'
import { find, split } from 'lodash'
import { useFrame } from '../hooks/useFrame'
import data from '../data/dataset.json'

export default function PromiseCard({ id, setPromise }) {
  const promise = find(dataset, { ID: id + 1 })
  const cardWidth = 375
  const [showSensitive, setShowSensitive] = useState(false)
  const { width, height } = useFrame()
  const widthAvailable = width - cardWidth
  const tileWidth = widthAvailable / 30
  const tileHeight = height / Math.ceil(data.length / 30)

  const left = ((id % 30) * widthAvailable) / 30 - 15 + tileWidth / 2
  const leftToUse = useMemo(() => {
    if (left < cardWidth) {
      return left + cardWidth + tileWidth * 3
    }
    return left - 30
  }, [left])
  const top = (height / Math.ceil(data.length / 30)) * Math.floor(id / 30) - 30 + tileHeight / 2
  return (
    <div
      className={S.CardPromise}
      style={{
        background: getBackground(promise.event_type),
        left: leftToUse,
        top: top > 70 ? -20 : top + 20,
        zIndex: 999,
      }}
    >
      <div className={S.CardBody}>
        <div className={S.BlockLeftRotate}>
          <div
            onClick={() => {
              setPromise(null)
            }}
            className={S.CloseCard}
          >
            <img src={closeIcon} />
          </div>
          <div className={S.NumberBlock}>
            <div className={S.DatePromise}>{promise.date}</div>
            <div className={S.BorderLine}></div>
            <div className={S.NumberPromise}>VIOLATION #{promise.ID}</div>
          </div>
        </div>
        <div>
          <div className={S.TitlePromise}>{promise.title}</div>
          <div className={S.ImagePromise}>
            {promise.is_sensitive === 'yes' && !showSensitive ? (
              <div className={S.SensitiveContent}>
                <div>
                  <img src={iconSensitive} />
                </div>
                <div className={S.TitleSensitive}>Sensitive content</div>
                <div>
                  This photo contains sensitive content which some people may find offensive or
                  disturbing.
                </div>
                <div className={S.ViewButton} onClick={() => setShowSensitive(true)}>
                  View
                </div>
              </div>
            ) : (
              <img
                className={S.ImageRadius}
                width="100%"
                height={221}
                src={`/violations/${promise.ID}.jpg`}
                alt={`Violation #${promise.ID}`}
              />
            )}
          </div>
          <div className={S.topicBox}>
            <div className={S.labelTopic}>Topic</div>
            <div className={S.TopicName}>{promise.topic}</div>
          </div>
          <div>
            <div className={S.labelTopic}>Event type</div>
            <div className={S.EventList}>
              {split(promise.event_type, ',').map((event) => (
                <div className={S.EventContainer} key={event}>
                  <div
                    className={S.CircleEvent}
                    style={{ background: eventTypeToColor(event.trim()) }}
                  ></div>
                  <div className={S.NameEvent}>{event}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
