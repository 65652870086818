export default {
  key: 'S01',
  sequence: [
    { type: 'still', duration: 200 },
    {
      type: 'interpolate',
      key: 'glitch',
      duration: 250,
      from: {
        glitch: 0,
      },
      to: {
        glitch: 1500,
      },
    },
  ],
}
