import { flatMap, fromPairs, groupBy, keyBy, mapValues, range, sum } from 'lodash'
import { EXAMPLE_POST_1 } from '../../constants'
import {
  data,
  years,
  countByYear,
  violenceTopic,
  objectableTopic,
  safetyTopic,
  abuseAndNudityTopic,
  bullyngAndHarassmentTopic,
  suicideAndSelfInjuryTopic,
  twoOrMore,
} from '../../data'
import { IDS_36 } from '../../utils'

const TILE_N_INITIAL = fromPairs(years.map((year) => [`step${year}`, 0]))
const TILE_N_FINAL = fromPairs(years.map((year) => [`step${year}`, countByYear[year] + 3]))
const TILE_SPEED = 8

const Infinity = 1000000000

const paddingMessage = 18
const heightTextBeforeAudio = 58
const heightAudios = [
  heightTextBeforeAudio + 171,
  heightTextBeforeAudio + 139,
  heightTextBeforeAudio + 155,
  heightTextBeforeAudio + 155,
]

export default {
  key: 'S02',
  sequence: [
    {
      type: 'interpolate',
      key: 'glitch',
      duration: 200,
      from: {
        glitch: 1500,
      },
      to: {
        glitch: 0,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_first',
      duration: 1500,
      detatch: true,
      from: {
        top: ({ height }) => height + 50 + 'px',
        opacity: 1,
      },
      to: {
        top: ({ height }, { node }) => {
          let contentHeight = 50
          let children = node.children
          for (let i = 0; i < children.length - 2; i++) {
            // last 2 children are the arrow
            contentHeight += children[i].offsetHeight + 12 // margin bottom of message
          }
          return -contentHeight + 'px'
        },
        opacity: 1,
      },
    },

    { type: 'still', duration: 550 },
    {
      type: 'interpolate',
      key: 'tile_zero',
      detatch: true,
      duration: 300,
      from: {
        width: '150px',
        height: '400px',
        left: ({ width }) => (width + 10) / 2 + 'px',
        top: ({ height }) => (height - 420) / 2 + 'px',
        opacity: 1,
      },
      to: {
        width: ({ width }) => (width - 375) / 30 + 'px',
        height: ({ height, numTiles }) => Math.ceil(height / Math.ceil(numTiles / 30)) + 'px',
        left: '375px',
        top: '0px',
        opacity: 1,
      },
    },
    {
      type: 'interpolate',
      key: 'title',
      duration: 50,
      detatch: true,
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
    { type: 'still', duration: 950 },

    // fade out messaggio Scroll down...
    {
      type: 'interpolate',
      key: 'messages_first',
      duration: 100,
      to: {
        top: ({ height }, { node }) => {
          let contentHeight = 50
          let children = node.children
          for (let i = 0; i < children.length - 2; i++) {
            // last 2 children are the arrow
            contentHeight += children[i].offsetHeight + 12 // margin bottom of message
          }
          return -contentHeight + 'px'
        },
        opacity: 0,
      },
    },
    // movimento prima tile rossa
    {
      type: 'sequence',
      key: 'unused',
      detatch: true,
      children: flatMap(range(1, EXAMPLE_POST_1), (n) => [
        {
          key: 'tile_zero',
          type: 'interpolate',
          duration: TILE_SPEED,
          to: {
            width: ({ width }) => (width - 375) / 30 + 'px',
            height: ({ height, numTiles }) => Math.ceil(height / Math.ceil(numTiles / 30)) + 'px',
            left: ({ width }) => ((n % 30) * (width - 375)) / 30 + 375 + 'px',
            top: ({ height, numTiles }) =>
              (height / Math.ceil(numTiles / 30)) * Math.floor(n / 30) + 'px',
            opacity: 1,
          },
        },
      ]).concat({
        key: 'tile_zero',
        type: 'interpolate',
        duration: TILE_SPEED,
        to: {
          width: ({ width }) => (width - 375) / 30 + 'px',
          height: ({ height, numTiles }) => Math.ceil(height / Math.ceil(numTiles / 30)) + 'px',
          left: ({ width }) => ((EXAMPLE_POST_1 % 30) * (width - 375)) / 30 + 375 + 'px',
          top: ({ height, numTiles }) =>
            (height / Math.ceil(numTiles / 30)) * Math.floor(EXAMPLE_POST_1 / 30) + 'px',
          opacity: 0,
        },
      }),
    },
    ...flatMap(years, (_year, i) => {
      const year = parseInt(_year, 10)
      const prevYears = years.filter((_, j) => j < i)
      const count = countByYear[_year]
      const prevCount = prevYears.reduce((acc, item) => acc + countByYear[item], 0)
      const tile_n_to = { ...TILE_N_INITIAL }
      for (const y of prevYears) {
        tile_n_to[`step${y}`] = countByYear[y] + 3
      }
      tile_n_to[`step${_year}`] = countByYear[_year] + 3

      return [
        // fade in contatore container
        {
          type: 'interpolate',
          key: 'info_pill',
          detatch: true,
          duration: 10,
          from: {
            opacity: 0,
            width: '318px',
          },
          to: {
            opacity: 1,
            width: '318px',
          },
        },
        // fade in contatore anni
        {
          type: 'interpolate',
          key: 'year_swatch_container',
          detatch: true,
          duration: 10,
          from: {
            opacity: 0,
            width: '80px',
          },
          to: {
            opacity: 1,
            width: '80px',
          },
        },
        {
          type: 'interpolate',
          key: 'year_swatch_style',
          detatch: true,
          duration: 10,
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
        // fade in contatore delle tile
        {
          type: 'interpolate',
          key: 'number_tiles_style',
          detatch: true,
          duration: 10,
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
        // contatore anni
        {
          type: 'interpolate',
          key: 'year_swatch',
          detatch: true,
          duration: 10,
          from: {
            textContent: '',
          },
          to: {
            textContent: year.toString(),
          },
        },
        // contatore delle tile
        {
          type: 'interpolate',
          key: 'number_tiles',
          detatch: true,
          duration: count * TILE_SPEED,
          from: {
            textContent: 1,
          },
          to: {
            textContent: (count + prevCount).toString(),
          },
        },
        // entrata tile sul muro
        {
          type: 'interpolate',
          key: 'tile_n',
          duration: count * TILE_SPEED,
          from: {
            ...TILE_N_INITIAL,
            stepFilter: 0,
            intensity: 1000,
          },
          to: {
            ...tile_n_to,
            stepFilter: 0,
            intensity: 1000,
          },
        },
        // entrano tutti gli audio
        {
          type: 'interpolate',
          key: `audios`,
          duration: 200,
          from: {
            top: ({ height }) => `${height + 50}px`,
          },
          to: {
            top: ({ height }) =>
              `${height - sum(heightAudios.slice(0, i + 1)) - (i + 1) * paddingMessage}px`,
          },
        },
        {
          type: 'interpolate',
          key: `audio_${i + 1}`,
          duration: 150,
          from: {
            transform: `translateY(${heightAudios[i]})`,
          },
          to: {
            transform: `translateY(${
              heightAudios[i] * sum(heightAudios.slice(0, i)) - i * paddingMessage
            })`,
          },
        },
      ]
    }),
    // fade out indicatore anno
    {
      type: 'interpolate',
      key: 'year_swatch_container',
      detatch: true,
      duration: 10,
      to: {
        opacity: 0,
        width: '0px',
      },
    },

    // ridimensionamento contatore container
    {
      type: 'interpolate',
      key: 'info_pill',
      detatch: true,
      duration: 75,
      to: {
        opacity: 1,
        width: '95px',
      },
    },
    // uscita messaggi audio
    {
      type: 'interpolate',
      key: `audios`,
      duration: 1100,
      detatch: true,
      to: {
        top: ({ height }) => -height * 2 + 'px',
      },
    },

    { type: 'still', duration: 100 },

    // messaggi testuali dopo gli audio
    {
      type: 'interpolate',
      key: 'messages_second',
      duration: 1500,
      detatch: true,
      from: {
        transform: ({ height }) => {
          return `translateY(${height + 50}px)`
        },
      },
      to: {
        transform: ({ height }) => {
          return `translateY(${-height - 100}px)`
        },
      },
    },
    { type: 'still', duration: 650 },
    // messaggi Violence
    {
      type: 'interpolate',
      key: 'messages_violence',
      duration: 1500,
      detatch: true,
      from: {
        transform: ({ height }) => {
          return `translateY(${height + 50}px)`
        },
      },
      to: {
        transform: ({ height }) => {
          return `translateY(${-height - 100}px)`
        },
      },
    },
    { type: 'still', duration: 100 },
    // filtra tessere Violence
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: {
        ...TILE_N_FINAL,
        stepFilter: 1000,
        intensity: 1000,
      },
    },
    // cambia numero contatore Violence
    {
      type: 'interpolate',
      key: 'number_tiles',
      duration: 50,
      to: {
        textContent: violenceTopic.toString(),
      },
    },
    { type: 'still', duration: 700 },
    // schiarisci tessere filtrate
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: { ...TILE_N_FINAL, stepFilter: 1000, intensity: 0 },
    },
    {
      type: 'interpolate',
      key: 'violence_sample',
      duration: 100,
      detatch: true,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_violence_post',
      duration: 25,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    { type: 'still', duration: 300 },
    {
      type: 'interpolate',
      key: 'violence_sample',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_violence_post',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },
    // Objectable samples
    // messagggi Objectionable
    {
      type: 'interpolate',
      key: 'messages_objectable',
      duration: 1500,
      detatch: true,
      from: {
        transform: ({ height }) => {
          return `translateY(${height + 50}px)`
        },
      },
      to: {
        transform: ({ height }) => {
          return `translateY(${-height - 100}px)`
        },
      },
    },
    { type: 'still', duration: 100 },
    // filtra tessere Objectionable
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      to: {
        ...TILE_N_FINAL,
        stepFilter: 2000,
        intensity: 1000,
      },
    },
    // cambia numero contatore Objectionable
    {
      type: 'interpolate',
      key: 'number_tiles',
      duration: 50,
      to: {
        textContent: objectableTopic.toString(),
      },
    },
    { type: 'still', duration: 700 },
    // schiarisci tessere filtrate
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: { ...TILE_N_FINAL, stepFilter: 2000, intensity: 0 },
    },
    {
      type: 'interpolate',
      key: 'objectable_sample',
      duration: 100,
      detatch: true,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_objectable_post',
      duration: 25,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    { type: 'still', duration: 300 },

    {
      type: 'interpolate',
      key: 'objectable_sample',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_objectable_post',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },
    // messaggi Safety
    {
      type: 'interpolate',
      key: 'messages_safety',
      duration: 1500,
      detatch: true,
      from: {
        transform: ({ height }) => {
          return `translateY(${height + 50}px)`
        },
      },
      to: {
        transform: ({ height }) => {
          return `translateY(${-height - 100}px)`
        },
      },
    },
    { type: 'still', duration: 100 },
    // filtra tessere Safety
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: { ...TILE_N_FINAL, stepFilter: 3000, intensity: 1000 },
    },
    // cambia numero contatore Safety
    {
      type: 'interpolate',
      key: 'number_tiles',
      duration: 50,
      to: {
        textContent: safetyTopic.toString(),
      },
    },
    { type: 'still', duration: 700 },
    // schiarisci tessere filtrate
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: { ...TILE_N_FINAL, stepFilter: 3000, intensity: 0 },
    },
    {
      type: 'interpolate',
      key: 'safety_sample',
      duration: 100,
      detatch: true,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_safety_post',
      duration: 25,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    { type: 'still', duration: 300 },

    {
      type: 'interpolate',
      key: 'safety_sample',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_safety_post',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },

    // 36 IDS
    {
      type: 'interpolate',
      key: 'messages_36',
      duration: 1500,
      detatch: true,
      from: {
        transform: ({ height }) => {
          return `translateY(${height + 50}px)`
        },
      },
      to: {
        transform: ({ height }) => {
          return `translateY(${-height - 100}px)`
        },
      },
    },
    { type: 'still', duration: 100 },
    // filtra tessere 36
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: { ...TILE_N_FINAL, stepFilter: 4000, intensity: 1000 },
    },
    // cambia numero contatore 36
    {
      type: 'interpolate',
      key: 'number_tiles',
      duration: 50,
      to: {
        textContent: IDS_36.length.toString(),
      },
    },
    { type: 'still', duration: 300 },
    // schiarisci tessere filtrate
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: { ...TILE_N_FINAL, stepFilter: 4000, intensity: 0 },
    },

    {
      type: 'interpolate',
      key: 'messages_36_sample',
      duration: 100,
      detatch: true,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_36_post',
      duration: 25,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    { type: 'still', duration: 300 },

    {
      type: 'interpolate',
      key: 'messages_36_sample',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },
    {
      type: 'interpolate',
      key: 'messages_36_post',
      duration: 50,
      detatch: true,
      to: {
        opacity: 0,
      },
    },

    //Two or More messages
    {
      type: 'interpolate',
      key: 'messages_two_or_more',
      duration: 1500,
      detatch: true,
      from: {
        transform: ({ height }) => {
          return `translateY(${height + 50}px)`
        },
      },
      to: {
        transform: ({ height }) => {
          return `translateY(${-height - 100}px)`
        },
      },
    },
    { type: 'still', duration: 100 },
    // filtra tessere Two or More
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      to: {
        ...TILE_N_FINAL,
        stepFilter: 5000,
        intensity: 1000,
      },
    },
    // cambia numero contatore Two or More
    {
      type: 'interpolate',
      key: 'number_tiles',
      duration: 50,
      to: {
        textContent: twoOrMore.toString(),
      },
    },
    { type: 'still', duration: 500 },
    // mostra tutte le tessere
    {
      type: 'interpolate',
      key: 'tile_n',
      duration: 100,
      detatch: true,
      to: {
        ...TILE_N_FINAL,
        stepFilter: 6000,
        intensity: 1000,
      },
    },
    // aggiorna contatore tutte tessere
    {
      type: 'interpolate',
      key: 'number_tiles',
      detatch: true,
      duration: 50,
      to: {
        textContent: data.length.toString(),
      },
    },
    {
      type: 'interpolate',
      key: 'legend_style',
      duration: 200,
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    {
      type: 'interpolate',
      key: 'overlay',
      duration: 50,
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    { type: 'still', duration: 300 },
    { type: 'interpolate', key: 'overlay', to: { opacity: 0 }, duration: 50 },
    { type: 'still', duration: 300 },
  ],
}
