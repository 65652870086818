import { useEffect, useRef } from 'react'
import { useFrame } from './useFrame'

export function useAnimationContext(args = {}) {
  const frame = useFrame()
  const ctx = useRef({ ...frame, ...args })

  useEffect(() => {
    ctx.current = { ...frame, ...args }
  }, [frame])

  return ctx
}
