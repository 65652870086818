import React, { useMemo , useRef} from 'react'
import { compileAnimation } from './animations'
import { Scroller, ScrollerTools } from './components/Scroller'
import { Video } from './sections/mobile/S01_Video'
import RawAnimationVideo from './sections/mobile/S01_Video.animation'
import RawAnimationWall from './sections/mobile/S02_Wall.animation'
import RawAnimationResponse from './sections/mobile/S03_Response.animation'
import RawAnimationWallStretch from './sections/mobile/S04_WallStretch.animation'
import RawAnimationRepository from './sections/mobile/S05_Repository.animation'
import RawAnimationDiveDeeper from './sections/mobile/S06_DiveDeeper.animation'
import { Glitchable } from './components/Glitch'
import { Wall } from './sections/mobile/S02_Wall'
import { WallAnchor } from './sections/mobile/S02B_WallAnchor'
import { Responses } from './sections/mobile/S03_Response'
import { WallStretch } from './sections/mobile/S04_WallStretch'
import Repository from './sections/mobile/S05_Repository'
import DiveDeeper from './sections/mobile/S06_DiveDeeper'
import { useFrame } from './hooks/useFrame'

export function Mobile() {
  const frame = useFrame()
  const videoRef = useRef()
  const scrollHintRef = useRef()
  const anims = useMemo(() => {
    const animationVideo = compileAnimation(RawAnimationVideo, frame)
    const animationWall = compileAnimation(RawAnimationWall, frame)
    const animationResponse = compileAnimation(RawAnimationResponse, frame)
    const animationWallStretch = compileAnimation(RawAnimationWallStretch, frame)
    const animationRepository = compileAnimation(RawAnimationRepository, frame)
    const animationDiveDeeper = compileAnimation(RawAnimationDiveDeeper, frame)

    const ANIMATIONS = [
      animationVideo,
      animationWall,
      animationResponse,
      animationWallStretch,
      animationRepository,
      animationDiveDeeper,
    ]

    return {
      animationVideo,
      animationWall,
      animationResponse,
      animationWallStretch,
      animationRepository,
      animationDiveDeeper,
      all: ANIMATIONS,
    }
  }, [frame])

  return (
    <Glitchable scrollHintRef={scrollHintRef}>
      {/* Need to account for the height of the topbar */}
      <Scroller externalHeight={44} animations={anims.all} videoRef={videoRef} scrollHintRef={scrollHintRef}>
        <Video animation={anims.animationVideo} videoRef={videoRef} />
        <Wall animation={anims.animationWall} />
        <WallAnchor animation={anims.animationResponse} />
        <Responses animation={anims.animationResponse} />
        <WallStretch animation={anims.animationWallStretch} />
        <Repository animation={anims.animationRepository} />
        <DiveDeeper animation={anims.animationDiveDeeper} />
      </Scroller>
    </Glitchable>
  )
}
