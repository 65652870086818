/* eslint-disable react/jsx-max-props-per-line */
import { scaleLinear } from 'd3-scale'
import { chain, last, zip, union } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { Message } from '../../components/Message'
import { Tile } from '../../components/Tile'
import { data as initialData } from '../../data'
import messages from '../../data/messages_2.json'
import { useAnimatedProps } from '../../hooks/useAnimatedProps'
import {
  useAnimatedStyle,
  useAnimatedStyleSelector,
  useAnimatedStyleVector,
  useAnimatedStyleVectorStatic,
} from '../../hooks/useAnimatedStyle'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import { useFrame } from '../../hooks/useFrame'
import { eventTypesToColors, eventTypesToGrayScale, eventTypesToHateScale } from '../../utils'
import S from './S04_WallStretch.module.css'

const responseTypesCount = {}

function tween(color1, color2, min, max) {
  return scaleLinear().domain([min, max]).range([color1, color2]).clamp(true)
}

initialData
  .filter((d) => d.facebook_response_type !== 'no response')
  .map((d) => d.facebook_response_type)
  .forEach((frt) => {
    if (responseTypesCount[frt]) {
      responseTypesCount[frt] = responseTypesCount[frt] + 1
    } else {
      responseTypesCount[frt] = 1
    }
  })

const sortedResponseTypes = chain(responseTypesCount)
  .toPairs()
  .sortBy('1')
  .map('0')
  .map((item, idx) => [item, idx])
  .fromPairs()
  .value()

const finalOrdering = chain(initialData)
  .map((datum, i) => {
    return { ...datum, pk: i }
  })
  .filter((d) => d.facebook_response_type !== 'no response')
  .sort((a, b) => {
    const at = sortedResponseTypes[a.facebook_response_type]
    const bt = sortedResponseTypes[b.facebook_response_type]
    return at === bt ? a.pk - b.pk : at - bt
  })
  .map((item, i) => [item.pk, i])
  .fromPairs()
  .value()

const finalUnsorted = chain(initialData)
  .map((datum, i) => {
    return { ...datum, pk: i }
  })
  .filter((d) => d.facebook_response_type === 'no response')
  .map((item, i) => [item.pk, i])
  .fromPairs()
  .value()

const responded = Object.keys(finalOrdering).length
const notResponded = initialData.length - responded

const ROWS = Math.ceil(responded / 30) + Math.ceil(notResponded / 30)
const ROWS_INITIAL = Math.ceil(initialData.length / 30)

const data = initialData.map((datum, i) => {
  const sortIdx = finalOrdering[i] ?? finalUnsorted[i]
  const priority = Math.round(Math.random() * 1000)
  const height = 1,
    width = 1
  const tileHeightInitial = height / ROWS_INITIAL
  const tileHeightSorted = height / ROWS
  const tileHeightStretched = height / Math.ceil(responded / 30)
  const tileWidthInitial = width / 30
  const tileWidthStretched = width / 30
  const leftInitial = ((i % 30) * width) / 30
  const topInitial = (height / ROWS_INITIAL) * Math.floor(i / 30)
  const leftSorted = ((sortIdx % 30) * width) / 30
  const topSorted =
    (height / ROWS) * Math.floor(sortIdx / 30) +
    (datum.facebook_response_type === 'no response'
      ? Math.ceil(responded / 30) * tileHeightSorted
      : 0)
  const leftStretched = ((sortIdx % 30) * width) / 30
  const topStretched = (height / Math.ceil(responded / 30)) * Math.floor(sortIdx / 30)

  const leftScale = scaleLinear()
    .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
    .range([
      leftInitial,
      leftSorted,
      leftSorted,
      leftStretched,
      leftStretched,
      leftStretched,
      leftInitial,
    ])
    .clamp(true)
  const topScale = scaleLinear()
    .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
    .range([topInitial, topSorted, topSorted, topStretched, topStretched, topStretched, topInitial])
    .clamp(true)
  let heightScale, widthScale
  if (sortIdx > 0) {
    heightScale = scaleLinear()
      .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
      .range([
        tileHeightInitial,
        tileHeightSorted,
        tileHeightSorted,
        tileHeightStretched,
        tileHeightStretched,
        tileHeightStretched,
        tileHeightInitial,
      ])
      .clamp(true)
    widthScale = scaleLinear()
      .domain([3100, 4000, 5000, 6000, 6500])
      .range([
        tileWidthInitial,
        tileWidthStretched,
        tileWidthStretched,
        tileWidthStretched,
        tileWidthInitial,
      ])
      .clamp(true)
  } else {
    heightScale = scaleLinear()
      .domain([2000, 3000, 3100, 4000, 5000, 6000, 6500])
      .range([
        tileHeightInitial,
        tileHeightSorted,
        tileHeightSorted,
        tileHeightStretched,
        tileHeightStretched,
        height,
        tileHeightInitial,
      ])
      .clamp(true)
    widthScale = scaleLinear()
      .domain([3100, 4000, 5000, 6000, 6500])
      .range([tileWidthInitial, tileWidthStretched, tileWidthStretched, width, tileWidthInitial])
      .clamp(true)
  }
  const backgroundOriginal = datum.backgroundOriginal
  const backgroundGray = datum.backgroundGray
  const backgroundTarget =
    datum.facebook_response_type === 'no response' ? backgroundGray : backgroundOriginal
  const backgroundCurrent = (step) => {
    const x = zip(backgroundOriginal, backgroundTarget).map(([color1, color2]) => {
      const result = tween(color1, color2, priority, priority + 500)(step)
      return result
    })
    return x
  }

  return {
    ...datum,
    priority,
    pk: i,
    sortIdx,
    isFirst: finalOrdering[i] === 0,
    leftScale,
    topScale,
    widthScale,
    heightScale,
    opacityScale:
      datum.facebook_response_type === 'no response'
        ? scaleLinear().domain([3000, 3100]).range([1, 0]).clamp(true)
        : () => 1,
    backgroundScale: backgroundCurrent,
  }
})

const dataByResponseType = {}
for (const datum of initialData) {
  if (
    datum.facebook_response_type !== 'no response' &&
    datum.facebook_response_type !== 'denied and enforced later'
  ) {
    const res = datum.facebook_response_type
    if (res == 'changed policy' || res == 'removed content') {
      //unisce due facebook_response_type in un unico array
      if (dataByResponseType['changed policy & removed content']) {
        dataByResponseType['changed policy & removed content'].push(datum)
      } else {
        dataByResponseType['changed policy & removed content'] = [datum]
      }
    } else if (dataByResponseType[res]) {
      dataByResponseType[res].push(datum)
    } else {
      dataByResponseType[res] = [datum]
    }
  }
}

const responsesSorted = Object.keys(dataByResponseType).sort(
  (a, b) => dataByResponseType[a].length - dataByResponseType[b].length
)

function createFillStyle(ctx, x, y, width, height, colors) {
  if (colors.length === 1) {
    return colors[0]
  } else {
    var grd = ctx.createLinearGradient(x, y, x, y + height)
    for (let i = 0; i < colors.length; i++) {
      grd.addColorStop((i / (colors.length - 1)).toString(), colors[i])
    }
    return grd
  }
}

function drawRect(ctx, datum, step, width, height) {
  const backgroundCurrent = datum.backgroundScale(step)

  const rect = {
    opacity: datum.opacityScale(step),
    left: datum.leftScale(step) * width,
    top: datum.topScale(step) * height,
    height: datum.heightScale(step) * height,
    width: datum.widthScale(step) * width,
    'z-index': datum.sortIdx === 0 && step >= 5000 && step <= 6000 ? 1 : 0,
  }

  const fillStyle = createFillStyle(
    ctx,
    rect.left,
    rect.top,
    rect.width,
    rect.height,
    backgroundCurrent
  )

  ctx.globalAlpha = rect.opacity
  ctx.fillStyle = fillStyle
  ctx.fillRect(rect.left, rect.top, rect.width, rect.height)
}

export function WallStretch({ scrollId, progress, animation }) {
  const animCtx = useAnimationContext({ numTiles: data.length })
  const pageRef = useRef()

  const countSwatchRef = useRef()
  const countSwatchContainerRef = useRef()
  const responsesRef = useRef()
  const message00Ref = useRef()
  const message01Ref = useRef()
  const message02Ref = useRef()
  const message03Ref = useRef()
  const message04Ref = useRef()
  const message05Ref = useRef()

  const { width, height } = useFrame()

  const canvasRef = useRef()

  const tilesAnimator = useMemo(() => {
    return animation.animations.tile(progress, animCtx)
  }, [progress, animCtx])
  const respAnimator = useMemo(() => {
    return animation.animations.recolor(progress, animCtx)
  }, [progress, animCtx])

  useAnimatedStyle(canvasRef, (...args) => {
    const aaa = tilesAnimator(...args)
    if (!aaa) {
      return null
    }
    const { step } = aaa
    let ctx = canvasRef.current.getContext('2d')
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    for (const datum of data) {
      drawRect(ctx, datum, step, width, height)
    }
    const d = data.find((datum) => datum.isFirst)
    drawRect(ctx, d, step, width, height)
    return {}
  })

  useAnimatedStyle(responsesRef, animation.animations.responses(progress, animCtx))

  useAnimatedStyleSelector(responsesRef, '.tile', (...args) => {
    const aaa = respAnimator(...args)
    if (!aaa) {
      return null
    }
    const { step } = aaa
    const { node } = args[0]
    const response = node.dataset.response
    const index = parseInt(node.dataset.index, 10)
    const datum = dataByResponseType[response][index]
    const initialColors = eventTypesToColors(datum.event_type)
    const finalColors = eventTypesToHateScale(datum.event_type)
    const grayColors = eventTypesToGrayScale(datum.event_type)
    let currentColors = null
    if (step <= (responsesSorted.length - 1) * 1000) {
      const rowsGreyedOut = Math.floor(step / 1000)
      const respsGrayedOut = responsesSorted.slice(0, rowsGreyedOut)
      if (respsGrayedOut.includes(response)) {
        currentColors = grayColors
      } else {
        const rowGreyingOut = rowsGreyedOut
        if (response === responsesSorted[rowGreyingOut]) {
          currentColors = zip(initialColors, grayColors).map(([color1, color2]) =>
            tween(color1, color2, rowGreyingOut * 1000, (rowGreyingOut + 1) * 1000)(step)
          )
        } else {
          currentColors = initialColors
        }
      }
    } else if (step <= responsesSorted.length * 1000) {
      if (response !== last(responsesSorted)) {
        currentColors = zip(grayColors, initialColors).map(([color1, color2]) =>
          tween(
            color1,
            color2,
            (responsesSorted.length - 1) * 1000,
            responsesSorted.length * 1000
          )(step)
        )
      } else {
        currentColors = initialColors
      }
    } else {
      currentColors = zip(initialColors, finalColors).map(([color1, color2]) =>
        tween(
          color1,
          color2,
          responsesSorted.length * 1000,
          (responsesSorted.length + 1) * 1000
        )(step)
      )
    }
    if (currentColors.length === 1) {
      return {
        background: currentColors[0],
      }
    } else {
      return {
        background: `linear-gradient(${currentColors.join(', ')})`,
      }
    }
  })

  useAnimatedStyle(message00Ref, animation.animations.message00(progress, animCtx))
  useAnimatedStyle(countSwatchContainerRef, animation.animations.count_style(progress, animCtx))
  const tileNumberAnimator = useMemo(() => {
    return animation.animations.count_text(progress, animCtx)
  }, [progress, animCtx])
  useAnimatedProps(countSwatchRef, (...args) => {
    const aaa = tileNumberAnimator(...args)
    if (!aaa) {
      return null
    }
    const { textContent } = aaa
    return { textContent: Math.floor(textContent).toString(10) }
  })
  useAnimatedStyle(message01Ref, animation.animations.message01(progress, animCtx))
  useAnimatedStyle(message02Ref, animation.animations.message02(progress, animCtx))
  useAnimatedStyle(message03Ref, animation.animations.message03(progress, animCtx))
  useAnimatedStyle(message04Ref, animation.animations.message04(progress, animCtx))
  useAnimatedStyle(message05Ref, animation.animations.message05(progress, animCtx))

  return (
    <div
      style={{ width: '100vw', position: 'relative' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      ref={pageRef}
    >
      <div className="menu-placeholder" />
      <div className={S.wall}>
        <div className={S.countSwatchContainer} ref={countSwatchContainerRef}>
          <div className={S.countSwatchLabel}>violations</div>
          <div className={S.countSwatch} ref={countSwatchRef}></div>
        </div>
        <canvas ref={canvasRef} width={width} height={height}></canvas>
      </div>
      <div className={S.responses} ref={responsesRef}>
        {responsesSorted.map((resp) => (
          <div key={resp} className={S.responsesRow}>
            {dataByResponseType[resp].map((datum, i) => (
              <Tile
                key={i}
                style={{
                  width: width / dataByResponseType[resp].length,
                }}
                data-response={resp}
                data-index={i}
                className="tile"
                colors={eventTypesToColors(datum.event_type)}
              />
            ))}
          </div>
        ))}
      </div>
      <Message
        style={{ width: 260, position: 'absolute', right: 18, zIndex: 5 }}
        body={messages[0]}
        boxShadow
        ref={message00Ref}
      />
      <Message
        style={{ width: 250, position: 'absolute', right: 18, zIndex: 5 }}
        body={messages[1]}
        boxShadow
        ref={message01Ref}
      />
      <Message
        style={{ width: 240, position: 'absolute', right: 18, zIndex: 5 }}
        body={messages[2]}
        boxShadow
        ref={message02Ref}
      />
      <Message
        style={{ width: 220, position: 'absolute', right: 18, zIndex: 5 }}
        body={messages[3]}
        boxShadow
        ref={message03Ref}
      />
      <Message
        style={{ width: 260, position: 'absolute', right: 18, zIndex: 5 }}
        body={messages[4]}
        boxShadow
        ref={message04Ref}
      />
      <Message
        style={{ width: 240, position: 'absolute', right: 18, zIndex: 5 }}
        body={messages[5]}
        boxShadow
        ref={message05Ref}
      />
    </div>
  )
}
