import { flatMap, keyBy, range, uniqBy } from 'lodash'
import dataset from '../../data/dataset.json'

const data = dataset.map((item) => ({ ...item, year: parseInt(item.date.split('/')[2], 10) }))
const responses = Object.keys(keyBy(data, 'facebook_response_type')).length - 3 // the "no response" should not be accounted for
const dataTookActions = dataset.filter((d) => d.facebook_response_type !== 'no response').length

const messagesInterpolations = [
  {
    type: 'interpolate',
    key: 'message03',
    duration: 550,
    from: {
      top: ({ height }) => height + 150 + 'px',
    },
    to: {
      top: ({ height }) => height / 2 + 'px',
    },
  },
  {
    type: 'interpolate',
    key: 'message04',
    duration: 550,
    from: {
      top: ({ height }) => height + 150 + 'px',
    },
    to: {
      top: ({ height }) => height / 2 + 'px',
    },
  },

  {
    type: 'interpolate',
    key: 'message05',
    duration: 550,
    from: {
      top: ({ height }) => height + 150 + 'px',
    },
    to: {
      top: ({ height }) => height / 2 + 'px',
    },
  },
]

const messagesInterpolationsOut = [
  {
    type: 'interpolate',
    key: 'message03',
    duration: 550,
    detatch: true,
    to: {
      top: ({ height }, { node }) => -200 + 'px',
    },
  },
  {
    type: 'interpolate',
    key: 'message04',
    duration: 550,
    detatch: true,
    to: {
      top: ({ height }, { node }) => -200 + 'px',
    },
  },
  {
    type: 'interpolate',
    key: 'message05',
    duration: 550,
    to: {
      top: ({ height }, { node }) => -200 + 'px',
    },
  },
]

export default {
  key: 'S04',
  sequence: [
    // primo messaggio
    {
      type: 'interpolate',
      key: 'message00',
      duration: 1100,
      detatch: true,
      from: {
        top: ({ height }) => height + 150 + 'px',
      },
      to: {
        top: ({ height }, { node }) => -200 + 'px',
      },
    },
    { type: 'still', duration: 200 },

    // fade out tessere
    {
      type: 'interpolate',
      key: 'tile',
      duration: 250,
      detatch: true,
      from: {
        step: 0,
      },
      to: {
        step: 2000,
      },
    },
    { type: 'still', duration: 50 },
    // aggiorna contatore tessere
    {
      type: 'interpolate',
      key: 'count_text',
      detatch: true,
      duration: 100,
      from: {
        textContent: dataset.length.toString(),
      },
      to: {
        textContent: dataTookActions.toString(),
      },
    },
    { type: 'still', duration: 500 },
    // secondo messaggio
    {
      type: 'interpolate',
      key: 'message01',
      duration: 1100,
      detatch: true,
      from: {
        top: ({ height }) => height + 150 + 'px',
      },
      to: {
        top: ({ height }, { node }) => -200 + 'px',
      },
    },
    // riordine tessere
    {
      type: 'interpolate',
      key: 'tile',
      duration: 250,
      from: {
        step: 2000,
      },
      to: {
        step: 3000,
      },
    },

    // fade out tessere grigie
    {
      type: 'interpolate',
      key: 'tile',
      duration: 150,
      to: {
        step: 3100,
      },
    },
    // stretch tessere rimaste
    {
      type: 'interpolate',
      key: 'tile',
      duration: 300,
      to: {
        step: 4000,
      },
    },
    // non si sa
    {
      type: 'interpolate',
      key: 'tile',
      duration: 10,
      to: {
        step: 5000,
      },
    },
    // non si sa
    {
      type: 'interpolate',
      key: 'filler',
      duration: 10,
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    { type: 'still', duration: 100 },
    // fade out counter tile
    {
      type: 'interpolate',
      key: 'count_style',
      detatch: true,
      duration: 10,
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
    // zoom tessera blu
    {
      type: 'interpolate',
      key: 'tile',
      duration: 100,
      to: {
        step: 6000,
      },
    },
    // non si sa
    {
      type: 'interpolate',
      key: 'responses',
      duration: 10,
      detatch: true,
      from: {
        height: ({ height }) => height * responses + 'px',
        opacity: 0,
      },
      to: {
        height: ({ height }) => height * responses + 'px',
        opacity: 1,
      },
    },
    // terzo messaggio One case
    {
      type: 'interpolate',
      key: 'message02',
      duration: 1100,
      detatch: true,
      from: {
        top: ({ height }) => height + 150 + 'px',
      },
      to: {
        top: ({ height }, { node }) => -200 + 'px',
      },
    },
    { type: 'still', duration: 750 },
    // animazione entrata righe e grigio
    ...flatMap(range(1, responses + 1, 1), (x) => {
      return [
        x > 1
          ? {
              type: 'interpolate',
              key: 'recolor',
              duration: 250,
              detatch: true,
              from: {
                step: 0,
              },
              to: {
                step: (x - 1) * 1000,
              },
            }
          : null,
        {
          type: 'interpolate',
          key: 'responses',
          duration: x === 1 ? 50 : 500,
          detatch: true,
          from: {
            height: ({ height }) => height * responses + 'px',
            opacity: 0,
          },
          to: {
            height: ({ height }) => (height * responses) / x + 'px',
            opacity: 1,
          },
        },
        // x > 1 ? { type: 'trap' } : null,
        x > 1 ? messagesInterpolations[x - 2] : null,
        // x > 1 ? { type: 'trap' } : null,
        x > 1 ? messagesInterpolationsOut[x - 2] : null,
      ].filter((t) => t !== null)
    }),
    {
      type: 'interpolate',
      key: 'tile',
      duration: 100,
      detatch: true,
      to: {
        step: 6500,
      },
    },
    {
      type: 'interpolate',
      key: 'recolor',
      duration: 100,
      to: {
        step: responses * 1000,
      },
    },
  ],
}
