import React, { useRef } from 'react'
import { compileAnimation } from './animations'
import { Scroller } from './components/Scroller'
import { Video } from './sections/desktop/S01_Video'
import RawAnimationVideo from './sections/desktop/S01_Video.animation'
import RawAnimationWall from './sections/desktop/S02_Wall.animation'
import RawAnimationResponse from './sections/desktop/S03_Response.animation'
import RawAnimationWallStretch from './sections/desktop/S04_WallStretch.animation'
import RawAnimationRepository from './sections/desktop/S05_Repository.animation'
import RawAnimationDiveDeeper from './sections/desktop/S06_DiveDeeper.animation'
import { Glitchable } from './components/Glitch'
import { Wall } from './sections/desktop/S02_Wall'
import { WallAnchor } from './sections/desktop/S02B_WallAnchor'
import { Responses } from './sections/desktop/S03_Response'
import { WallStretch } from './sections/desktop/S04_WallStretch'
import Repository from './sections/desktop/S05_Repository'
import DiveDeeper from './sections/desktop/S06_DiveDeeper'

const AnimationVideo = compileAnimation(RawAnimationVideo)
const AnimationWall = compileAnimation(RawAnimationWall)
const AnimationResponse = compileAnimation(RawAnimationResponse)
const AnimationWallStretch = compileAnimation(RawAnimationWallStretch)
const AnimationRepository = compileAnimation(RawAnimationRepository)
const AnimationDiveDeeper = compileAnimation(RawAnimationDiveDeeper)

const ANIMATIONS = [
  AnimationVideo,
  AnimationWall,
  AnimationResponse,
  AnimationWallStretch,
  AnimationRepository,
  AnimationDiveDeeper,
]

export function Desktop() {
  const videoRef = useRef();
  const scrollHintRef = useRef();
  return (
    <Glitchable scrollHintRef={scrollHintRef}>
      {/* Need to account for the height of the topbar */}
      <Scroller externalHeight={80} animations={ANIMATIONS} videoRef={videoRef} scrollHintRef={scrollHintRef}>
        <Video animation={AnimationVideo} videoRef={videoRef} />
        <Wall animation={AnimationWall} />
        <WallAnchor animation={AnimationResponse} />
        <Responses animation={AnimationResponse} />
        <WallStretch animation={AnimationWallStretch} />
        <Repository animation={AnimationRepository} />
        <DiveDeeper animation={AnimationRepository} />
      </Scroller>
    </Glitchable>
  )
}
