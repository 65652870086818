import { useEffect } from 'react'

const ANIMATORS = {}
let uniqueId = 1

export function useAnimateEngine() {
  useEffect(() => {
    function animate() {
      const animators = Object.values(ANIMATORS)
      for (let i = 0; i < animators.length; i++) {
        const animator = animators[i]
        animator()
      }
      window.animatorShouldForceRender = false
      requestAnimationFrame(animate)
    }
    requestAnimationFrame(animate)
  }, [])
}

export function registerAnimator(animatorFn) {
  const animatorId = uniqueId
  uniqueId = uniqueId + 1
  ANIMATORS[animatorId] = animatorFn
  return () => {
    delete ANIMATORS[animatorId]
  }
}
