import React from 'react'
import Footer from '../../components/Footer'
import { Reports } from '../../components/Reports'
import S from './S05_Repository.module.css'

export default function DiveDeeper({ scrollId, animation }) {
  return (
    <div
      style={{ width: '100vw', height: '1766px' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="repository"
      data-jump-displacement="0"
    >
      <div className={S.RelatedArticles}>
        <div className={S.RelatedArticlesTitle}>
          TTP has been exposing Facebook’s Broken Promises for years. Explore the series_
        </div>
        <div className={S.Posts}>
          <Reports />
        </div>
      </div>
      <Footer />
    </div>
  )
}
