import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import S from './Message.module.css'

function _Message({ body, style, boxShadow = false }, ref) {
  return (
    <div style={style} className={boxShadow ? S.MessageWithBoxShadow : S.Message} ref={ref}>
      {ReactHtmlParser(body)}
    </div>
  )
}

export const Message = React.forwardRef(_Message)
