import React, { createContext, useContext, useRef } from 'react'
import { useAnimatedStyleVector } from '../hooks/useAnimatedStyle'
import S from './Glitch.module.css'
import glitchData from '../assets/glitch.json'
import { Tile } from './Tile'
import { scaleLinear } from 'd3-scale'
import { useFrame } from '../hooks/useFrame'

const Context = createContext()

const SINGLE_ANIMATION_DURATION = 10

window.onscroll = function () {
  var title = document.getElementById('title')
  var keepScrollingCTA = document.getElementById('keepScrollingCTA')
  var containerTitle = document.getElementById('containerTitle')

  if (window.pageYOffset > 300 && window.pageYOffset < 349) {
    containerTitle.style.setProperty('animation-play-state', 'running')
    containerTitle.style.setProperty('opacity', '1')
    title.style.setProperty('opacity', '1')
    keepScrollingCTA.style.setProperty('opacity', '1')
  }

  if (window.pageYOffset > 350 + visualViewport.height) {
    containerTitle.classList.add(S.introMessageContainerOut)
    containerTitle.classList.remove(S.introMessageContainer)
    containerTitle.style.setProperty('animation-play-state', 'running')
  }
}

function makeColorFromFigma({ r, g, b, a }) {
  r = Math.round(r * 255)
    .toString(16)
    .padStart(2, '0')
  g = Math.round(g * 255)
    .toString(16)
    .padStart(2, '0')
  b = Math.round(b * 255)
    .toString(16)
    .padStart(2, '0')
  return `#${r}${g}${b}`
}

export function Glitchable({ children, scrollHintRef }) {
  const ref = useRef()
  const { width, height, isMobile } = useFrame()

  const GLITCH_WIDTH = glitchData.absoluteBoundingBox.width
  const GLITCH_HEIGHT = glitchData.absoluteBoundingBox.height

  const wScale = width / GLITCH_WIDTH
  const hScale = height / GLITCH_HEIGHT

  const scale = Math.max(wScale, hScale)

  let hMargin = 0,
    wMargin = 0

  if (GLITCH_WIDTH * scale > width) {
    wMargin = (GLITCH_WIDTH * scale - width) / 2
  }
  if (GLITCH_HEIGHT * scale > height) {
    hMargin = (GLITCH_HEIGHT * scale - height) / 2
  }

  return (
    <Context.Provider value={{ ref, scrollHintRef }}>
      {children}
      <div ref={ref} className={S.glitchMaster}>
        <div ref={scrollHintRef} className={S.introMessageContainer} id="containerTitle">
          <div className={isMobile ? S.introTitleMobile : S.introTitle} style={{ opacity: 1 }}>
            <div id="title">
              To build this tool, the Tech Transparency Project collected more than three years of reports showing violations of Facebook's own policies.
            </div>
            <div className={isMobile ? S.introCTAMobile : S.introCTA} style={{ opacity: 1 }} id="keepScrollingCTA">
              Keep scrolling to explore the database.
            </div>
          </div>
        </div>

        {glitchData.children.slice(0).map((gd) => {
          let colors = []
          if (gd.fills[0].type === 'SOLID') {
            colors = [makeColorFromFigma(gd.fills[0].color)]
          }
          if (gd.fills[0].type === 'GRADIENT_LINEAR') {
            colors = gd.fills[0].gradientStops.map((s) => makeColorFromFigma(s.color))
          }
          return (
            <Tile
              style={{
                position: 'absolute',
                top:
                  (gd.absoluteBoundingBox.y - glitchData.absoluteBoundingBox.y) * scale - hMargin,
                left:
                  (gd.absoluteBoundingBox.x - glitchData.absoluteBoundingBox.x) * scale - wMargin,
                width: gd.absoluteBoundingBox.width * scale,
                height: gd.absoluteBoundingBox.height * scale,
              }}
              data-pk={gd.id}
              key={gd.id}
              colors={colors}
            />
          )
        })}
      </div>
    </Context.Provider>
  )
}

export function useGlitchAnimation(animationFn, progress) {
  const { ref } = useContext(Context)
  useAnimatedStyleVector(ref, (...args) => {
    const anim = animationFn(...args)
    if (!anim) {
      return
    }
    const { glitch } = anim
    const { index } = args[0]
    const scale = scaleLinear()
      .domain([0, 1000 - SINGLE_ANIMATION_DURATION])
      .range([0, glitchData.children.length])
    const currentFloat = scale(glitch)
    const currentIndex = Math.floor(currentFloat)
    if (progress.isActive) {
      if (index < currentIndex) {
        return { opacity: 1 }
      } else if (index === currentIndex) {
        return { opacity: currentFloat - currentIndex }
      } else {
        return { opacity: 0 }
      }
    } else {
      return {}
    }
  })
}
