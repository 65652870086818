import React, { useContext, useEffect, useState } from 'react'

export const FrameContext = React.createContext()

export function FrameDetection({ children }) {
  const extraHeight = window.matchMedia('(max-width: 768px)').matches ? 80 : 80
  const [frame, setFrame] = useState({
    width: window.innerWidth,
    fullHeight: window.innerHeight,
    height: window.innerHeight - extraHeight,
    isMobile: window.matchMedia('(max-width: 768px)').matches,
  })

  useEffect(() => {
    const handler = () => {
      const extraHeight = window.matchMedia('(max-width: 768px)').matches ? 80 : 80
      setFrame({
        width: window.innerWidth,
        fullHeight: window.innerHeight,
        height: window.innerHeight - extraHeight,
        isMobile: window.matchMedia('(max-width: 768px)').matches,
      })
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  return (
    <FrameContext.Provider value={frame}>
      {typeof children === 'function' && children(frame)}
      {typeof children !== 'function' && children}
    </FrameContext.Provider>
  )
}
