/* eslint-disable react/jsx-max-props-per-line */
import React, { useRef } from 'react'
import data from '../../data/dataset.json'
import { useAnimatedStyle } from '../../hooks/useAnimatedStyle'
import { useAnimationContext } from '../../hooks/useAnimationContext'
import S from './S03_Response.module.css'

export function Responses({ scrollId, progress, animation }) {
  const animCtx = useAnimationContext({ numTiles: data.length })
  const pageRef = useRef()
  const screen = useRef()

  return (
    <div
      style={{ width: '100vw', height: '100vh', position: 'relative' }}
      data-scroll-id={scrollId}
      data-scroll-animation-key={animation.key}
      data-jump-name="facebook-response"
      data-jump-displacement="-19"
      ref={pageRef}
    >
      <div className="menu-placeholder" />

      <div className={S.part}>
        <p className={S.section}>Section II</p>
        <div className={S.title}>Facebook's Response_</div>
      </div>
      <div className={S.textContainer}>
        <p className={S.narrativeText}>
          Facebook has promised to act when its policies are broken.
        </p>
        <p className={S.narrativeCaption}>
          “Our policies define what is and isn’t allowed on Facebook technologies. <br />
          If content goes against our policies, we take action on it.”
        </p>
        <p className={S.narrativeCaptionSource}>—Facebook policies page, 2021</p>
      </div>
    </div>
  )
}
